@import "dark";

.invite-modal {
  width: 100%;
  padding-top: 20px;
  background: none;
  color: var(--primary-text);
  stroke: var(--primary-text);

  @media screen and (min-width: 768px) {
    width: 400px;
    height: auto;
  }

  //generics
  input {
    background-color: transparent;
    color: inherit;
  }
  .loader {
    color: #444444;
  }

  //share link
  .share-link-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 35px;
    margin-bottom: 10px;
  }

  .share-link-input {
    width: 70%;
    padding-right: 5px;
    border-right: none;
  }

  .copy-link-button {
    cursor: pointer;
    height: 100%;
    width: 30%;
    margin-left: 0px;
    background-color: #cacaca;
    font-size: 12px;
    border-radius: 0 3px 3px 0;
    border: none;
    //outline: #555555;

    &:hover {
      background-color: #bbbbbb;
    }

    &:focus {
      outline: none;
    }
  }

  //Form
  .email-input-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
    min-height: 30px;

    ::placeholder {
      color: inherit;
    }

    input {
      width: auto;
      min-height: 30px;
    }

    .loader-wrapper {
      position: absolute;
      right: 0;
    }
  }

  .invite-template-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 550;
    margin-bottom: 5px;
  }

  #customize-invite-info {
    margin-left: 7px;
  }

  #invite-info {
    max-width: 60%;
  }

  .template-editor {
    color: inherit;
    stroke: inherit;
    resize: none;
    border-radius: 2px;
    min-height: 200px;
    width: 100%;
    font-size: 14px;
    text-indent: 1px;
  }

  .ql-toolbar {
    stroke: inherit;
    color: inherit;
    .ql-stroke {
      stroke: inherit;
    }
    .ql-picker {
      color: inherit;
    }
  }

  .ql-editor {
    height: 200px;
    overflow-y: auto;
  }
  .template-editor:focus {
    outline: none;
  }

  .invite-modal-bottom-row {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .btn-primary {
    width: 150px;
  }
}

.invite-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  .invite-label {
    font-weight: 600;
    font-size: 1.2em;
    flex-grow: 1;
  }

  .share-link-toggle {
    flex-shrink: 0;
    font-size: 0.9em;
    cursor: pointer;
    display: flex;
    align-items: center;

    .link-icon {
      color: #979797;
      margin-left: 10px;
      border: 1px solid #979797;
      border-radius: 50%;
      padding: 5px;
    }

    &:hover .link-icon {
      background-color: #979797;
      color: white;
    }
  }
}
