@import "~styles/media-queries";
@import "dark";
$break-height: 680px;

.share-settings-modal-header {
  height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #dadada;
  padding: 0px 20px;
  font-size: 18px;
  font-weight: bold;

  .title {
    flex-grow: 1;
  }

  .btn-no-style.btn-close {
    margin: 0;
    padding: 0;
    top: 0;
    right: 0;
    position: relative;
    height: 100%;
    width: 25px;
  }
}

.share-settings-modal {
  width: 100%;
  margin: 0 auto;
  position: relative;
  font-size: 14px;
  padding: 0px 0 20px 0;

  @include sm {
    width: 730px;
  }

  .settings-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #dadada;
    height: 310px;
  }

  .privacy-setting-container {
    padding: 25px 20px;
    width: 50%;

    .radio-group-root {
      width: 100%;
    }

    .radio-group {
      margin-left: -5px;
    }

    .radio-check-container {
      padding: 5px;
    }

    .radio-label {
      font-family: HelveticaNowDisplay;
      font-weight: normal;
    }
  }

  .privacy-setting-description-container {
    display: flex;
    width: 50%;
    flex-direction: column;
    padding: 25px 20px;
    border-left: 1px solid #dadada;
    overflow-y: auto;

    .header {
      margin-bottom: 5px;
    }

    .image-container {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 5px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .placeholder {
      width: 100%;
      height: 180px;
      background-color: #515151;
      color: #dadada;

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }
  }

  .advanced-settings-title {
    padding: 15px 0px;
    font-weight: bold;
    cursor: pointer;

    svg {
      margin-left: 10px;
    }

    .fa-chevron-down {
      color: var(--primary-text);
    }
    .fa-chevron-up {
      color: #4182ff;
    }
  }

  /*
  .advance-settings-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    justify-content: space-between;
    padding: 0px 20px 0 49px;
    margin-bottom: 20px;

    .advanced-setting {
      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 30px;
    }

    .setting-label {
      font-weight: 700;
    }
  }
  */

  /*
  .default-settings-container {
    padding: 10px 20px;
    background-color: #eaeaea;
    color: #191919;
    display: flex;
    align-items: center;

    .default-checkbox {
      margin-right: 10px;
      padding: 0;
    }
  }
  */

  //passcode sub element
  //Passcode sub privacy
  .optional-passcode {
    display: flex;
    align-items: center;
    height: 40px;

    .passcode-switch {
      display: flex;
      align-items: center;
      width: 50%;
      font-weight: 700;
      padding: 0 15px 0 30px;
      box-sizing: border-box;

      .label {
        flex-grow: 1;
      }
    }

    .passcode-input {
      position: relative;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 50%;
      height: 25px;

      input {
        box-sizing: border-box;
        width: 100%;
      }

      .reveal-icon {
        position: absolute;
        right: 10px;
        color: #666;
        cursor: pointer;
      }

      &.error {
        border: 2px solid red;
      }
    }
  }

  .modal-action-btns {
    padding: 20px 20px 0px 20px;
    display: flex;
    justify-content: space-between;

    .btn {
      width: auto;
      max-width: 250px;
      height: 42px;
      font-weight: 500;
      border: none;
      padding: 0 20px;
    }

    .btn-cancel {
      background: #eaeaea;
      color: #191919;
    }
  }
}

//Full screen stuff
#share_settings_modal_base.full-screen {
  .share-settings-modal {
    width: 100%;
  }

  .settings-container {
    height: calc(100vh - 140px);
  }
}
