.grant-page {
  .spinner-container {
    margin-top: 80px;
  }
  p {
    font-size: 20px;
    text-align: center;
    margin: 80px auto 0 auto;
    max-width: 70vw;

    &.error {
      color: #E53935;
    }
  }
}
