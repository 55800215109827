@import "~styles/media-queries";
@import "dark";

.add-media-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .fa {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: var(--primary-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    color: white;
    margin-right: 20px;
  }

  button {
    background: none;
    border: none;
    color: inherit;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    padding: 8px 16px;
    height: auto;

    &:hover {
      background: rgba($color: #444, $alpha: 0.2);
    }
  }
}

.media-header {
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem 20px;
  height: 294px;
  display: flex;
  column-gap: 18px;
  color: var(--raisin);

  background: var(--concrete);
  background-color: rgba(0, 0, 0, 0.05);

  @include md {
    padding: 1rem 1rem 1rem 50px;
  }

  @include xl {
    padding-left: 80px;
  }

  &.collapse-text {
    grid-template-columns: 100% 0;

    .video-container {
      flex-grow: 1;
      max-width: 100%;
      width: 100%;
    }

    .text-content {
      width: 0;
    }
  }

  //Generics
  input,
  textarea {
    display: block;
    background: none;
    border: 1px solid #5f5f5f;
    border-radius: 5px;
    color: inherit;
    box-sizing: inherit;
    margin: 0;
    padding: 5px 9px;
    font-family: inherit;
    text-align: left;
    width: 100%;
    line-height: 1.25;

    &.error {
      border: 1px solid red;
    }

    &:focus {
      outline: 2px solid #4182ff;
      border-color: transparent;
    }
  }

  h3,
  p {
    line-height: 1.25;

    &.error {
      color: red;
    }
  }

  h3.error,
  p ::-webkit-input-placeholder,
  ::-moz-placeholder,
  ::-ms-placeholder,
  ::placeholder {
    color: var(--night-rider);
  }

  //errors
  .input-error-message {
    min-height: 0;
    font-size: 12px;
    margin: 0;
    padding: 0;
    font-style: italic;
    font-weight: bold;
    color: red;
    margin-top: 3px;
    padding-left: 0.5rem;
  }

  //Video Stuff
  .video-container {
    position: relative;
    display: flex;
    height: calc(294px - 2rem);
    //min-width: 300px;
    max-width: 45%;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
    margin: auto;

    &.editable {
      min-width: 30%;
      border: 1px solid #5f5f5f;
      border-radius: 5px;
    }

    &.no-video.editable {
      width: 50%;
    }

    //editor
    .media-video-btns {
      box-sizing: border-box;
      position: absolute;
      top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: white;
      background: #00000099;
      padding: 6px 3px;
      border-radius: 6px;

      &.add {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        background: none;
        border-radius: 5px;
        background-color: var(--granite-grey);
        padding: 20px 20px;
        text-align: center;
      }

      button {
        cursor: pointer;
        border: none;
        outline: none;
        margin: 0 8px;
        padding: 0;
        height: 36px;
        width: 36px;
        background: gray;
        border-radius: 50%;
        flex-shrink: 0;
        &.thumb {
          background: var(--primary-blue);
        }

        &.clear {
          background: #af2026;
        }

        svg {
          width: 16px;
          height: 16px;
          color: white;
          vertical-align: -0.25em;
        }
      }

      .info {
        margin-top: 20px;
      }

      .seperator {
        height: 36px;
        width: 1px;
        margin: 0 5px;
        background-color: white;
      }
    }
  }

  .video-error-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--night-rider);
    z-index: 2;
    text-align: center;
    color: var(--white);
  }

  .text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    box-sizing: inherit;
    flex-grow: 1;
    max-width: 560px;
  }

  //Title
  .title {
    flex-shrink: 0;
    max-height: 32%;
    overflow: hidden;
    box-sizing: inherit;

    &.editable {
      margin-bottom: 5px;
      padding: 2px;
    }

    h3,
    input {
      margin: 0;
      font-size: 28px;
      font-weight: bold;
    }
  }

  //Description
  .description {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    box-sizing: inherit;

    &.editable {
      flex-grow: 1;
      padding: 2px;
    }

    p,
    textarea {
      font-size: 16px;
    }

    textarea {
      resize: none;
      overflow-y: auto;
      vertical-align: top;
      flex-grow: 1;
    }

    p {
      margin: 0;
      overflow-wrap: anywhere;
    }
  }

  .author {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    font-size: 16px;

    .avatar {
      height: 24px;
      width: 24px;
      margin-right: 5px;

      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .star-icon {
      width: 14px;
      height: 14px;
      margin-left: 8px;

      circle {
        fill: #4182ff;
      }
      path {
        fill: white;
      }
    }
  }
}
