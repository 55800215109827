@import "~styles/media-queries";

.add-media-tray-container {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin-top: 77px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  font-size: 16px;

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    right: 0;
  }

  .add-media-tray {
    height: 100%;
    width: 350px;
    display: flex;
    flex-direction: column;
    background: var(--app-background-2);
    position: absolute;
    right: 0;
    top: 0;
    flex-direction: column;

    transition-timing-function: ease;
    transition-duration: 0.2s;
    transition-property: transform;

    &.open {
      transform: translate(-800px, 0);
    }

    //Material UI
    .MuiTabs-flexContainer {
      color: #464646;
    }

    .active-tab-indicator {
      background: var(--button-background-1);
      color: var(--button-background-1);
    }

    .tabs-root {
      padding: 0 10px;
      background: none;
    }

    .tab-container {
      height: 100%;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      .tab-content {
        flex-grow: 1;
        overflow: hidden;
        overflow-y: auto;
      }

      .button-container {
        display: flex;
        justify-content: space-between;
        background-color: #e5e5e5;
        color: var(--primary-text);
        padding: 10px 15px;
        height: 30px;

        .cancel-btn {
          width: 100%;
          border: none;
          bottom: 0;
          align-self: center;
          background: transparent;
          color: var(--primary-text);
          cursor: pointer;
        }

        .clear-btn {
          align-items: flex-start;
          background-color: transparent;
          background-color: var(--button-background-2);
          border: 1px solid #acacac;
          border-radius: 4px;
          color: white;
          height: 100%;
          width: 110px;
          cursor: pointer;
        }

        .add-asset-btn {
          align-items: flex-end;
          background-color: var(--primary-blue);
          color: white;
          border: none;
          border-radius: 4px;
          height: 100%;
          width: 110px;
          cursor: pointer;
        }
      }
    }

    .loader-container {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      z-index: 1;
      background: #00000044;
    }
  }
}

@import "_dark";