@import "styles/media-queries";

.external-access-view {
  .magic-link {
    margin-top: 25px;
    font-weight: bold;
    font-weight: 500;
  }
  .magic-link-icon {
    font-size: 19px;
  }
  .magic-link-label {
    padding-left: 10px;
  }

  .magic-link-requested {
    .information {
      max-width: 450px;
    }

    .separator {
      margin: 0px;
      height: 50px;
    }
  }
  .resend-link-btn {
    color: var(--primary-blue);
    background: transparent;
    font-weight: bold;
    text-decoration: underline;
    font-size: 18px;
    height: 20px;
    padding: 1px;
    margin-left: 1px;
    border-radius: 0px;

    &:hover {
      background-color: inherit;
    }
  }

  .alert-error {
    max-width: 400px;
    background-color: var(--secondary-red);
    color: var(--primary-red);
    font-size: 14px;
    font-weight: 500;
    padding: 15px;
    border-radius: 4px;
    margin: 0px 0px 35px 0px;
  }

  .sign-in {
    width: 350px;

    .action-btn {
      .standard-size {
        width: 100%;
      }
    }

    .input-container {
      width: 100%;
      position: relative;

      .input-arrow {
        font-size: 26px;
        margin-left: -35px;
        cursor: pointer;
        z-index: 5;
      }

      .input-icon {
        position: absolute;
        left: 10px;
      }

      .email-input {
        width: 100%;
        text-align: center;

        &.sign-in {
          text-align: center;
        }
      }
    }
  }
}
