.spinner-wrapper {
  position: relative;
  height: 86px;
  width: 86px;
  margin: 0 auto;
  overflow: hidden;
}

.spinner {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 60px;
  width: 60px;
  border: 3px solid transparent;
  border-top-color: #777;
  border-radius: 50%;
  animation: spin 2s linear infinite;

  span {
    content: '';
    position: absolute;
    border: 3px solid transparent;
    border-radius: 50%;
  }

  span:first-child {
    border-top-color: #4182ff;
    top: -12px;
    left: -12px;
    right: -12px;
    bottom: -12px;
    animation: spin 3s linear infinite;
  }

  span:nth-child(2) {
    border-top-color: #fff;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    animation: spin 4s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
