.modal-body {
  max-width: 600px;
  display: flex;
  margin: auto;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .btn-yes {
    background: #4182ff;
    color: #ffffff;
    width: 46%;
    height: 45px;

    &:hover {
      background: #337aff;
    }
  }

  .btn-cancel {
    background: #515151;
    color: #ffffff;
    width: 46%;
    height: 45px;
    margin-right: 10px;
  }

  .prompt {
    padding-top: 15px;
    font-weight: 400;
    word-wrap: break-word;
  }

  .header {
    display: block;
    text-align: center;
    font-size: 1.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    word-wrap: break-word;
  }
}
