@import '~styles/media-queries';
@import '~styles/media-grid-breakpoints';
@import 'dark';

.navbar {
  background-color: var(--app-background-2);
  border-bottom: 1px solid var(--app-background);
  display: flex;
  position: relative;
  flex-direction: row;
  background: white;
  height: 75px;
  padding-bottom: 2px;

  //Containers
  > div {
    align-self: center;
    display: flex;
    align-items: center;
  }

  .left-container {
    flex-grow: 1;
    align-self: center;
    padding-left: 20px;

    @include sm {
      padding-left: 50px;
    }

    @include xl {
      padding-left: 80px;
    }
  }
}
