@import "~styles/media-queries";

//Content
.main-page {
  --primary-blue: #4182ff;

  display: flex;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  .spinner-container {
    margin: auto;
  }

  .content {
    width: 100%;
    position: relative;

    .spinner-wrapper {
      margin-top: 150px;
    }

    //media-header styling override
    .video-container {
      .spinner-wrapper {
        margin: auto;
      }
    }

    @include sm {
      width: calc(100% - 350px);
    }
  }
}

.minimal-view .content {
  width: 100%;
}
