.dark .share-modal {
  .email-container {
    background-color: var(--dk-modal-background);
  }

  .email-preview {
    background-color: var(--dk-modal-background-accent);
  }

  .copy-container {
    background-color: var(--dk-modal-background);
  }

  .email-templates {
    background-color: var(--dk-modal-background);
  }
}
