.error-page {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .error-content {
    font-size: 20px;
    text-align: center;
    max-width: 70vw;
  }

  a {
    display: inline;
    margin-left: 5px;
  }
}
