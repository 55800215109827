.dark .my-stuff {

  .buttons {
    .media-button {
      color: white;
      background-color: var(--button-background-2);

      &:hover {
        background-color: #c5c5c5;
      }
    }
  }

  .bread-crumb {
    background-color: var(--button-background-2);
    border-bottom: none;
  }

  .add-link-view {
    color: #acacac;
    .form-row {
      input {
        color: #acacac;
      }
    }
  }

  .image-preview-placeholder {
    background-color: var(--button-background-2);
  }

  .action-btns {
    background-color: var(--button-background-2);

    .clear-btn {
      color: white;
    }
  }
}
