.dark .activity-tab-container {
  color: var(--dk-primary-text);

  .activity-header {
    background: none;
  }

  .activity-row {
    border-color: var(--dk-action-tray-separator);
  }

  .search-and-filter-container {
    .search-container {
      background-color: var(--mine-shaft);
      border-color: transparent;
    }

    .expand-container #dropdown-arrow-up {
      border-bottom: 10px solid var(--dk-dropdown-background);
    }
  }

  .filter-dropdown-container {
    color: var(--dk-primary-text);
    background-color: var(--dk-dropdown-background);
    box-shadow: 0 0 8px 0 #323232;
  }
}
