@import "~styles/media-queries";

.media-viewer-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .viewer-info-bar {
    height: 40px;
    position: relative;
    padding: 3px 10px 3px 45px;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    display: flex;
    align-items: center;

    flex-flow: row wrap;

    @include sm {
      padding-left: 25px;
    }

    @include xl {
      padding-left: 80px;
    }

    .title-container {
      margin-right: 20px;
      font-size: 1.05em;
    }

    .size-container {
      margin-right: 5px;
      font-size: 1.2em;
      font-weight: bold;
      text-align: right;
      white-space: nowrap;
      flex-grow: 1;
    }

    .options-container {
      margin-left: 5px;
      display: flex;
      align-items: center;

      .dropdown {
        margin-top: 2px;
      }

      button {
        //height:38px;
        font-size: 19px;
        color: #fff;
        width: 20px;
        text-align: right;

        &:hover {
          color: #ccc;
        }
      }
    }
  }

  .back-btn {
    width: 30px;
    height: 30px;
    background: none;
    color: white;
    padding: 0;
    padding-top: 5px;
    font-size: 16px;
    text-align: left;

    &.dark {
      color: #B2B2B2;
    }

    &:hover {
      color: #ddd;
    }
  }



  .image-viewer,
  .video-viewer,
  .filestack-viewer {
    text-align: center;
    overflow: hidden;
    flex-grow: 1;
    height: 100%;

    img,
    video {
      margin-top: 5px;
      max-height: 90%;
      max-width: 90%;

      @include custom-height(660px) {
        margin-top: 40px;
      }
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .website-message-view {
    display: flex;
    align-items: center;
    flex-direction: column;

    .message {
      text-align: center;
      width: 80%;
      margin: 10% 0 30px 0;
    }

    .btn {
      color: white;
      background-color: var(--button-background-1);
      padding: 4px 15px;
      margin-left: 10px;
      font-weight: 500;
    }
  }
}

.video-viewer {
  position: relative;

  .is-playing {
    object-fit: contain;
  }

  .is-paused {
    object-fit: cover;
  }

  .video-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.8;
    z-index: 1;
  }

  .video-play-button {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    background: transparent;
    border: 2px solid white;
  }

  .spinner-overlay {
    position: absolute;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;

    .spinner-wrapper {
      margin: 0px !important;
    }
  }

  .no-source {
    background: black;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;

    p {
      color: white;
    }
  }
}


.blocked-content-view {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 96%;
  width: 100%;
  margin: auto;
  background: rgba(0, 0, 0, 0.4);
}

.blocked-content-body {
  max-width: 60%;
  text-align: center;
  color: white;
  font-size: 25px;
  button {
    border-radius: 3px;
    margin-left: 3px;
    height: 34px;
    font-size: 14px;
  }
}

#error-image-thumbnail {
  max-width: 200px;
  max-height: 200px;
}

.audio-player,
.youtube-player {
  margin: auto;
}

.audio-player:focus {
  outline: none;
}

#download-icon {
  color: white;
}

#notes-icon {
  margin-left: 10px;
}
