@import "~styles/media-queries";

.action-tray {
  background: var(--app-background-2);
  position: absolute;
  top: 0;
  left: 800px;
  height: 100%;
  width: 100%;

  transition-timing-function: ease;
  transition-duration: 0.2s;
  transition-property: transform;

  &.open {
    transform: translate(-800px, 0);
  }

  @include sm {
    position: relative;
    left: 0;
    width: 350px;

    &.open {
      transform: none;
    }
  }

  //Material UI
  .MuiTabs-flexContainer {
    color: #464646;
  }

  .active-tab-indicator {
    background: #464646;
  }

  .tabs-root {
    padding: 0 14px;
    background: none;
  }
}

.tabs-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .tab-container {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@import "_dark";
