.app.dark {
  height: 100%;
  overflow: hidden;
  background-color: var(--dk-app-background);
  color: var(--dk-primary-text);

  ::-webkit-scrollbar {
    width: 15px; /* width of the entire scrollbar */
  }
  ::-webkit-scrollbar-track {
    background: #646464; /* color of the tracking area */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #323232; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #646464; /* creates padding around scroll thumb */
  }
}
