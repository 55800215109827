@import '~styles/media-queries';
@import 'dark';

.footer {
  color: var(--primary-text);
  background-color: var(--app-background-2);
  border: none;
  height: 38px;
  background: #fcfcfc;
  overflow: hidden;
  -webkit-transition: height 300ms;
  transition: height 300ms;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 12px;
  align-items: center;
  justify-content: center;

  @include sm {
    width: calc(100% - 350px);
  }

  &.full-width {
    width: 100%;
  }

  &.closed {
    height: 0;
    border: none;
  }

  img {
    margin-left: 8px;
    height: 11px;
  }

  .btn-learn-more {
    text-decoration: none;
    padding: 2px 8px;
    font-size: 11px;
    border: 1px solid var(--primary-text);
    color: inherit;
    border-radius: 4px;
    margin-left: 40px;
    font-weight: bold;
  }

  .btn-close {
    color: inherit;
    margin-left: 30px;
    height: 100%;
    padding: 0 15px;
    font-size: 13px;
    position: absolute;
    bottom: 0;
    right: 5px;

    &:hover {
      color: #444;
    }
  }
}

.privacy {
  z-index: 4;
  width: 100%;
  background: none;
  color: var(--dk-primary-text);

  .btn-learn-more {
    display: none;
  }

  .btn-close {
    display: none;
  }
}

#footer-logo {
  max-height: 20px;
  height: auto;
  width: auto;
}
