.passcode-full-page {
  height: 100%;
  width: 100%;
}

.passcode-view {
  .information {
    max-width: 500px;
    margin-top: 30px;
  }

  .input-container {
    margin-top: 15px;
  }

  .passcode-label {
    font-size: 25px;
    padding-left: 16px;
  }

  .lock-icon {
    font-size: 23px;
  }

  .message {
    margin-top: 5px;
  }

  .user-access-input {
    background: rgba(220, 220, 220, 0.18);
    border: none;
    padding-right: 0px;
    max-width: 375px;
    padding-right: 0px;
    padding: 2px 0px 2px 12px;

    &:focus {
      background: none;
      border: 1px solid var(--primary-blue);
      padding: 1px 0px 1px 12px;
    }
  }

  .action-btn {
    width: 200px;
    margin-top: 15px;
  }

  .seperator {
    max-width: 425px;
    width: 100%;

    .line-div {
      max-width: 425px;
      width: 100%;
    }
  }

  .resend-link-btn {
    color: var(--primary-blue);
    background: transparent;
    font-weight: bold;
    text-decoration: underline;
    font-size: 18px;
    height: 20px;
    padding: 1px;
    margin-left: 1px;
    border-radius: 0px;
  }
}
