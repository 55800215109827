//User details container
.user-details-container {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;

    //dropmenu overrides
    .settings-dropdown .filter-dropdown-container {
        top: 35px;
        right: 0;
        width: 250px;

        svg {
            color: inherit;
        }

        div {
            box-sizing: border-box;
        }
    }

    //Open Settings Button
    .settings-icon-btn {
        flex-shrink: 0;
        height: 34px;
        width: 34px;
        border: none;
        color: white;
        background-color: var(--button-background-2);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    //User Button
    .user-details-button {
        background: none;
        color: inherit;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: none;
        border-left: 1px solid #696a6b;
        margin-left: 18px;
        padding-left: 18px;
        height: 34px;
        font-size: 16px;

        //inner containers
        &.has-user {
            .active-user {
                display: flex;
            }
            .inactive-user {
                display: none;
            }
        }

        .active-user {
            display: none;
            align-items: center;
            justify-content: center;
        }

        .avatar-container {
            position: relative;
            height: 34px;
            width: 34px;
            font-size: 16px;
            border: 2px solid transparent;
            padding: 2px;
        }

        .email {
            margin: 0 12px;
            color: inherit;
            //font-size: 16px;
        }

        //open states
        &.open {
            .avatar-container {
                border-color: var(--primary-blue);
            }

            svg {
                color: var(--primary-blue);
            }
        }
    }
}
