.dark .contact-tab {
  .members-container {
    border-color: var(--dk-action-tray-separator);
  }

  .member-row {
    border-color: var(--dk-action-tray-separator);
  }

  .contact-me-button {
    background-color: var(--dk-action-tray-button-background);
  }
  .mailto {
    color: #4182FF;
  }
}
