.dark {
  .media-grid-container {
    &.fade-enter-active {
      overflow: hidden;
    }
  }

  .media-items-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;

    @include custom-width(850px) {
      justify-content: flex-start;
      max-width: none;
    }
  }

  .sortable-media-container {
    padding: 0px 16px;
    width: 100%;
  }

  .add-media-tile {
    .add-media-button {
      background-color: var(--dk-media-item-background);
      svg {
        color: white;
      }
    }

    h3 {
      margin: 8px 0px 0px 0px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: var(--white);
    }

    p {
      margin: 0;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #bdbdbd;
    }
  }
}
