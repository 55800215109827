.dark .media-list {
 color: var(--dk-primary-text);

 .media-list-header {
    color: #acacac;

   .selection-btn {
     color: var(--dk-primary-text);
   }
 }
 .list-container {
   overflow-y: auto;
   .list {
     .list-item {
       .MuiButtonBase-root {
         color: #464646;
       }

       &.selected {
         background-color: rgb(68, 68, 68);
       }
     }

     .info {
       .type {
         color: #acacac;
       }
     }
   }
  }
}
