.avatar-container {
  height: 92px;
  width: 92px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid transparent;
  padding: 4px;
  font-size: 30px;

  &.highlighted {
    border-color: #4182ff;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.avatar {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .initials {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: rgb(255, 255, 255);
    background-color: #af2026;
  }
}
