.base-modal.dark {
  color: var(--dk-primary-text);
  background: var(--dk-modal-background);

  .members-modal-header {
    .title-bar {
      .dsr-name {
        color: var(--modal-accent-text);
      }
    }
  }

  .members-list {
    .member-item {
      .role-container {
        color: var(--modal-accent-text);
      }

      .button-hold {
        & > div {
          background: #4d4d4d;;
        }
      }
    }
  }
}
