@import "~styles/media-queries";

.logos-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  &.editable {
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      transition: all 0.1s;
    }
  }

  .company-logo {
    display: flex;
    max-height: 60px;
    max-width: 110px;

    @include sm {
      max-height: 29px;
      max-width: 175px;
    }

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  svg {
    margin-left: 5px;
  }
}

//Skeleton Loader
.logo-skeleton {
  height: 32px;
  width: 160px;
  border-radius: 3px;
  margin: 0 auto;

  @include sm {
    margin: 0;
  }
}
