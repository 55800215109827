@import '~styles/media-queries';
@import 'dark';

.activity-tab-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  //color: #3a3a3a;
  color: var(--primary-text);

  @include sm {
    width: 350px;
  }

  svg {
    color: inherit;
  }

  .hide {
    display: none;
  }

  .activity-header {
    background-color: var(--gallery);
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    text-indent: 25px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .icon-container {
      display: flex;
      margin: auto;
      position: relative;

      svg:hover {
        cursor: pointer;
      }

      .active-filters-indicator {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        position: absolute;
        top: -9px;
        right: -9px;
        color: white;
        text-indent: 0px;
        text-align: center;
        font-size: 14px;
        font-weight: 400px;
      }
    }

    .active-filters-container {
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 5px;
      padding-right: 2px;

      #filter-by-label {
        font-size: 13px;
        height: 20px;
        text-indent: 15px;
      }
      .active-filters-label {
        font-size: 13px;
        font-weight: 500;
        background-color: #e0dede;
        border-radius: 5px;
        width: auto;
        text-indent: 0px;
        padding: 5px;
        margin-left: 10px;
        margin-bottom: 3px;
        height: 15px;
        line-height: 15px;
      }

      #filter-label-clear-icon {
        margin-left: 5px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  //Search Header
  .search-and-filter-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    position: relative;
    margin: 10px 5px;

    @include sm {
      width: 350px;
    }

    .search-container {
      background-color: var(--white);
      border: 1px solid #d4d4d4;
      border-radius: 10px;
      display: flex;
      width: 70%;
      height: 25px;

      #search-input-icon {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
      }

      #email-input {
        color: inherit;
        background: none;
        -webkit-appearance: none;
        width: -webkit-fill-available;
        margin-right: 5px;
        border: none;
        padding: 0px;
        margin-left: 10px;
      }
      ::placeholder {
        color: inherit;
      }
    }

    .search-and-filter-icons {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 75px;
      margin-right: 15px;
    }

    .separator {
      height: 1px;
      background-color: #d8d8d8;
      width: 100%;
    }

    .expand-container {
      width: 0px;
      position: relative;

      #dropdown-arrow-up {
        border-bottom: 10px solid var(--dropdown-background);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        color: inherit;
        position: absolute;
        top: -10px;
        right: 10px;
      }
    }
  }

  //Custom Filter DropDown
  .filter-dropdown-container {
    color: var(--primary-text);
    background-color: var(--dropdown-background);
    box-shadow: 0 0 8px 0 #c9c9c9;
    width: 275px;
    border-radius: 8px;
    height: auto;
    padding-bottom: 15px;
    position: absolute;
    right: -2px;
    top: 35px;
    line-height: 40px;

    .filter-dropdown-content:not(.separator) {
      text-align: left;
      font-weight: normal;
      font-size: 14px;
      text-indent: 0px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .filter-dropdown-header {
        padding-left: 10px;
        padding-right: 10px;
        text-indent: 0px;
        text-transform: uppercase;
        font-weight: 550;
        color: #acacac;
        height: 30px;
        margin-bottom: 5px;
      }

      .filter-toggles {
        padding-left: 10px;
        padding-right: 10px;

        .filter-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 30px;

          &.switch-row {
            justify-content: space-between;
          }

          #filter-label {
            margin-left: 5px;
            line-height: 20px;
            font-size: 14px;
            font-weight: 500;
            height: 20px;
          }

          #switch-label {
            margin-left: 5px;
            font-weight: 500;
            font-size: 14px;
          }

          #filter-checkbox {
            height: 15px;
            width: 15px;
          }
        }
      }
    }

    .btn-clear {
      width: fit-content;
      height: 30px;
      display: flex;
      margin: auto;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  //Activity List
  .activity-list-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 7%;
  }

  .activity-row {
    height: 80px;
    border-bottom: 1px solid var(--action-tray-separator);
    padding: 20px;

    .activity-top-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .activity-row-left,
      .activity-row-right {
        display: flex;
        flex-direction: row;
        height: 100%;
      }

      #action-type {
        font-weight: 650;
        font-size: 15px;
        margin-left: 10px;
      }

      #action-timestamp {
        font-size: 13px;
      }
    }

    .activity-details-row {
      font-size: 14px;
      line-height: 18px;

      #description-tooltip {
        width: 300px;
      }
    }
  }
}
