.dark .share-modal {
  .share-link-builder {
    background-color: var(--dk-modal-background);

    .section.link {
      .title {
        color: #c1c1c1;
      }
      input {
        color: #c1c1c1;
        background-color: #222;
      }
    }

    .section.info-container .title-container {
      color: #c1c1c1;
    }
  }

}
