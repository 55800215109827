@import "~styles/media-queries";

.settings-dropdown {
  z-index: 1;

  .settings-btn {
    margin: 0;
    cursor: pointer;
    background: none;
    border: none;
    width: 100%;
    font-size: 15px;
    border-radius: 0;

    .loader-container {
      margin-right: 5px;

      .spinner-wrapper {
        height: 35px;
        width: 35px;
        margin-left: -10px;

        .spinner {
          height: 15px;
          width: 15px;
        }
      }
    }

    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.15);
    }
  }

  .basic-settings-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    height: 42px;
    padding: 0 12px;

    .icon {
      margin-right: 15px;
    }

    .label {
      text-align: left;
      flex-grow: 1;
    }

    .switch-container {
      margin-top: 2px;
      flex-shrink: 0;
    }
  }

  //Language List
  .language-menu-top-row {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 47px;
    padding: 0 12px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.15);
    }

    .label {
      font-size: 16px;
      margin-left: 10px;
      font-weight: 500;
      flex-grow: 1;
    }
  }

  .selected-language-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--primary-text);
    height: 26px;
    width: 26px;
    font-size: 14px;
    margin-bottom: 1px;
  }

  .language-list {
    height: 200px;
    overflow: auto;
    background-color: var(--dropdown-background-accent);

    .language-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      padding-right: 10px;
      padding-left: 10px;
      line-height: 33px;
      font-weight: 500;
      font-size: 14px;
      background: none;
      cursor: pointer;

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.1);
      }

      svg {
        margin-right: 10px;
      }
    }
  }

  //Version
  .version {
    font-size: 8px;
    text-align: right;
    border-top: 1px solid #efefef;
    color: #888;
    padding: 2px 12px;
  }
}

@import "dark";
