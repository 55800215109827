@import "~styles/media-queries";
@import 'dark';
$break-height: 680px;

.share-template-selector {

  //email
  .email-container {
    background-color: var(--modal-background);
    height: calc(100vh - 185px);
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;

    .message-preview {
      font-weight: normal;
      margin: 5px 0 10px 0;
    }

    @include custom-height($break-height) {
      height: 450px;
    }
  }

  .email-preview-container {
    flex-direction: row;
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 4px;
  }

  .email-templates {
    background-color: white;
    border: 1px solid var(--share-modal-border-color);
    border-radius: 4px;
    flex-shrink: 0;
    max-width: 35%;
    list-style: none;
    padding: 0;
    overflow: auto;
    margin: 0;
    margin-right: 15px;

    li {
      min-height: 40px;
      padding: 3px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid var(--share-modal-border-color);
      user-select: none;
      cursor: pointer;

      &.active {
        background-color: #666;
        color: white;
      }
    }

    li:hover {
      background-color: #b1b1b1;
      color: white;
    }
  }

  .email-preview {
    border: 1px solid var(--share-modal-border-color);
    border-radius: 4px;
    flex-grow: 1;
    padding: 18px;
    overflow: hidden;
    overflow-y: auto;
    width: 405px;

    &.multiple {
      padding: 10px;
    }

    .subject {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 12px;
    }

    .body {
      height: 100%;
      padding-bottom: 5px;
    }
  }

  .email-actions {
    text-align: right;
    padding: 12px 0;
    flex-shrink: 0;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .email-load-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  //warning
  .warning-label {
    background-color: #fff3cd;
    color: #856404;
    margin-bottom: 10px;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 1.3;
  }

  //Copy Link Area
  .copy-container {
    --share-modal-border-color: #666;

    background-color: var(--modal-background);
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 6px;

    @include custom-height($break-height) {
      padding: 15px 20px;
    }

    .title {
      display: none;
      align-items: center;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;

      @include custom-height($break-height) {
        display: flex;
      }
    }
    .title i {
      background: var(--primary-blue);
      border-radius: 50%;
      color: white;
      display: flex;
      height: 20px;
      width: 20px;
      font-size: 10px;
      margin-right: 8px;
      justify-content: center;
      align-items: center;
    }
  }

  .copy-action-container {
    display: flex;
    align-items: center;

    .link-preview-container {
      flex-grow: 1;
      position: relative;
    }

    input {
      color: inherit;
      background: none;
      font-size: 14px;
      height: 100%;
      width: 100%;
      padding: 8px 16px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid var(--share-modal-border-color);
    }
  }

  //Buttons
  .btn {
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    padding: 9px 0;
    color: white;
    width: 90px;
    height: 45px;
    cursor: pointer;

    &.btn-share {
      width: auto;
      padding: 9px 20px;
    }
  }

  .btn-copy {
    color: inherit;
    background: none;
    font-weight: normal;
    font-size: 14px;
    padding: 7px 20px;
    width: auto;
    text-decoration: underline;

    &:hover {
      color: var(--medium-grey);
    }
  }

  h3 {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
  }

  //Spinner
  .share-modal-spinner {
    height: 28px;
    width: 28px;
    margin: 2px auto;

    > div {
      height: 6px;
      width: 6px;
      color: white;
    }
  }
}
