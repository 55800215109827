@import "~styles/media-queries";
@import "font";

:root {
  font-family: HelveticaNowDisplay, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

html {
  background: #eeeeee;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

//App
.app {
  height: 100%;
  overflow: hidden;
  background-color: var(--app-background);
  color: var(--primary-text);

  //background image attached
  &.has-background {
    .content {
      background: rgba(0, 0, 0, 0.4);
    }

    .media-item {
      .title {
        span {
          color: var(--white);
        }
      }
      .media-type {
        color: var(--white);
      }
    }

    .media-grid {
      color: var(--white);
    }

    .media-header {
      //TODO: might need to add blur
      color: var(--white);
      background-color: rgba(0, 0, 0, 0.3);

      ::placeholder {
        color: var(--white);
        opacity: 0.8;
      }
    }

    .add-media-header button {
      color: var(--white);

      &:hover {
        background: rgba($color: #fff, $alpha: 0.2);
      }
    }

    .add-media-tile {
      p {
        color: var(--white);
      }
    }
  }

  //things in case an image is set
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  //router content
  .app-body {
    position: absolute;
    top: 112px;
    left: 0;
    bottom: 0;
    right: 0;
    @include sm {
      top: 77px;
    }

    &.minimal-view {
      top: 0px;
    }
  }

  //router stuff
  .transition-group {
    position: relative;
  }
  .routing-content {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  .fade-enter {
    opacity: 0.01;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in-out;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in-out;
  }
  .fade-exit-done {
    display: none;
  }

  //slide
  .fade-in-down-enter {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }
  .fade-in-down-exit {
    animation-duration: 0.4s;
    animation-name: fadeOutUp;
  }
  .fade-in-down-exit-done {
    display: none;
  }
}

//Font Assigning
button,
input,
textarea {
  font-family: HelveticaNowDisplay;
}

@import "app-dark";
@import "dropdown";
@import "generics";
@import "buttons";
@import "skeleton";
@import "animations";
@import "toast";
@import "inputs";
@import "themes";
