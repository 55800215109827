#video_poster_picker_modal {
  h1 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
}

.video-poster-picker-modal {
  width: 500px;
  transition: width 0.25s;
  overflow-x: hidden;

  &.generate {
    width: 800px;
  }

  .options {
    margin-top: 20px;
    display: flex;
  }

  button.option {
    background: none;
    border: 1px solid #dedede;
    color: inherit;
    height: 120px;
    margin-right: 20px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: rgba(102, 102, 102, 0.2);
    }

    &:last-child {
      margin-right: 0;
      margin-left: 20px;
    }
  }
}
