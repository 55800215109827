.dark .settings-dropdown {
  //buttons
  .settings-btn {
    color: white;
  }

  //language list
  .selected-language-icon {
    border-color: var(--dk-primary-text);
  }

  .language-list {
    background-color: var(--dk-dropdown-background-accent);

    .language-row:hover {
      background-color: rgba($color: #ffffff, $alpha: 0.1);
    }
  }

  //Version
  .version {
    border-color: #666666;
    color: #bbb;
  }
}
