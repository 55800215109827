@import '~styles/media-queries';

.preferences {
  height: auto;
  margin: auto;
  padding-top: 35px;
  padding-left: 5%;
  padding-right: 5%;
  overflow: auto;
  max-height: 100%;
  box-sizing: border-box;

  h1 {
    font-size: 28px;
    font-weight: 550;
    margin: 0;
    padding: 0;
  }

  h3 {
    margin: 0;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 5px;
    font-size: 14px;
  }

  .separator {
    height: 2px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    border-radius: 3px;
    background-color: #dedede;
  }

  .preferences-content {
    background-color: inherit;
    color: inherit;
    padding-bottom: 30px;
    width: 90%;
    margin: auto;
    padding-top: 25px;

    .user-information {
      margin-bottom: 20px;

      .label {
        font-weight: 550;
        font-size: 18px;
        margin-bottom: 5px;
      }
    }

    .preferences-header {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      font-weight: 550;
      font-size: 18px;
      height: 40px;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      .header-title {
        font-size: 22px;
      }

      .header-methods {
        color: #dedede;
      }
    }
  }

  .setting-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .setting-content {
      display: flex;
      flex-direction: column;

      .setting-title {
        font-weight: 550;
        font-size: 16px;
        margin-bottom: 5px;
      }

      .setting-description {
        font-weight: 400;
        font-size: 14px;

        @include sm {
          font-size: 16px;
        }
      }

      .all-communications {
        font-size: 22px;
      }

      .tooltip-icon {
        margin-left: 5px;
        max-width: 40%;
      }

      .tooltip {
        max-width: 40%;
      }
    }
  }

  .setting-row.all-communications {
    .setting-title {
      font-size: 22px;
    }
  }

  .save-button {
    border: none;
    margin-left: auto;
    width: 125px;
    padding: 10px 0;
    line-height: 1;
    border-radius: 20px;
    font-weight: 550;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
}
