.dark .share-modal-header {
  background-color: var(--dk-modal-background);
}

.dark .share-modal {
  .default-view {
    background-color: var(--dk-modal-background);

    li {
      border: 1px solid #414141;
    }

    .icon {
      background-color: #404040;
    }
  }

}
