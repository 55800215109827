.dark {
  .media-item {
    .media-thumb {
      background-color: var(--dk-media-item-background);
    }

    .title {
      color: var(--white);
    }

    .media-type {
      color: #bdbdbd;
    }

    .fallback-icon {
      height: 135px;
      svg {
        color: white;
      }
    }
  }
}
