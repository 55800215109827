@import "styles/media-queries";

.privacy-gate-welcome-loader {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modus-logo {
    position: absolute;
    top: 20px;
    height: 25px;
  }
  .welcome-message {
    margin-top: 45px;
    font-size: 20px;
    font-weight: bold;
  }
}
