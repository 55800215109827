@import '~styles/media-queries';
@import 'dark';

.hamburger-menu {
  margin: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  padding: 5px;

  //navbar specific
  margin-right: 10px;
  @include sm {
    display: none;
  }

  .hamburger-box {
    position: relative;
    width: 28px;
    height: 28px;
  }

  .hamburger-inner {
    background-color: var(--primary-text);
    position: absolute;
    width: 28px;
    height: 4.5px;
    border-radius: 4px;
    transition-timing-function: ease;
    transition-duration: 0.2s;
    transition-property: transform, filter, opacity;
  }

  .hamburger-inner:after,
  .hamburger-inner:before {
    position: absolute;
    width: 28px;
    height: 4.5px;
    border-radius: 4px;
    background-color: inherit;

    transition-timing-function: ease;
    transition-duration: 0.2s;
    transition-property: transform, filter, opacity;
  }

  .hamburger-inner {
    top: 2px;

    &:before {
      content: '';
      top: 9px;
    }

    &:after {
      content: '';
      top: 18px;
    }
  }

  //click
  &.active {
    .hamburger-inner {
      transform: rotate(45deg) translate(7px, 7px);
    }

    .hamburger-inner:before {
      transform: rotate(-45deg) translate(-8px, -8px);
      opacity: 0;
    }

    .hamburger-inner:after {
      transform: rotate(-90deg) translate(18px, 0px);
    }
  }
}
