@import "~styles/media-queries";
@import "dark";

.sortable-media-list {
  position: relative;
  display: grid;
  max-width: 100%;
  overflow: hidden;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 13px;
  justify-content: center;
  padding-left: 0;
  margin-block-start: 0em;

  @include md {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }

  .sortable-placeholder {
    width: 210px;
    height: 210px;
    margin-bottom: 15px;
    background-color: #666;
    opacity: 0.5;
    list-style-type: none;
    border-radius: 6px;
    border: 2px dotted #888 !important;
  }

  .sortable-placeholder-dark {
    width: 196px;
    height: 160px;
    margin-bottom: 15px;
    background-color: #666;
    opacity: 0.5;
    list-style-type: none;
    border-radius: 6px;
    border: 2px dotted #888 !important;
  }

  .media-item-container {
    list-style: none;
    width: 100%;
    border-radius: 3px;
  }
}
