@import './mixins/keyframe-mixin';

$animate-time: 0.4s;

.animate {
  -webkit-animation-duration: $animate-time;
  animation-duration: $animate-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

//Delays
.animate-delay-40 {
  animation-delay: 0ms;
}
.animate-delay-80 {
  animation-delay: 80ms;
}
.animate-delay-120 {
  animation-delay: 120ms;
}
.animate-delay-160 {
  animation-delay: 160ms;
}
.animate-delay-200 {
  animation-delay: 200ms;
}

//Fade In
.fadeIn {
  -moz-animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@include keyframe(fadeIn) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//Fade In Down
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@include keyframe(fadeInDown) {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

//Fade In Left
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@include keyframe(fadeInLeft) {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

//Fade In Right
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@include keyframe(fadeInRight) {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

//Fade Out Up
@include keyframe(fadeOutUp) {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
}

//Flip In X
.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}
@include keyframe(flipInX) {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
    //-webkit-animation-timing-function: ease-in;
    //animation-timing-function: ease-in;
    opacity: 0;
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

//Flip In Y
.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}
@include keyframe(flipInY) {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 60deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 60deg);
    opacity: 0;
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
