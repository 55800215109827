@import "dark";

.media-item {
  display: block;
  position: relative;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: auto;
  cursor: pointer;
  text-decoration: none;

  &.zoom-out-delete {
    animation: zoom forwards 1.5s ease-out 1;
  }

  &.deleted {
    opacity: 0.5;
  }

  .media-thumb {
    background-color: var(--media-item-background);
    width: 100%;
    height: 135px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-radius: 4px;

    .pending-spinner {
      margin: auto;
    }
  }

  .title {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: var(--raisin);

    span {
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .media-type {
    color: var(--dove-gray);
    overflow-x: auto;
  }

  .btn-delete {
    background-color: #af2026;
    color: white;
    position: absolute;
    top: 7px;
    right: 7px;
    border: 0;
    height: 35px;
    width: 35px;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
    }
  }

  .fallback-icon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;

    svg {
      color: #787878;
    }
  }

  .deleted-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: black;
    opacity: 0.5;
  }

  #deleted-info {
    max-width: 180px;
    color: white;
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
    width: 250px;
    min-width: 250px;
    opacity: 1;
    transform-origin: 125px 125px;
  }
  50% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    width: 0;
    min-width: 0;
    transform: scale(0);
    opacity: 0;
    padding: 0;
    margin: 0;
  }
}
