.fs-temp-overlay {
  background-color: black;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;

  &.fsFadeIn {
    -moz-animation-name: fsFadeIn;
    -webkit-animation-name: fsFadeIn;
    animation-name: fsFadeIn;
  }

  @keyframes fsFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
}
