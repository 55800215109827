.media-grid-breakpoint-container {
  display: flex;
  //background: blue;
  margin: 0 auto;
  width: 100%;

  @include custom-width(1080px) {
    //background: purple;
    max-width: 675px;
  }

  @include custom-width(1320px) {
    //background: limegreen;
    max-width: 90%;
  }

  @include custom-width(1560px) {
    //background: orange;
    max-width: 90%;
  }

  @include custom-width(1800px) {
    //background: orange;
    max-width: 90%;
  }
}

.minimal-view .media-grid-breakpoint-container {
  max-width: 90%;
}

.dark {
  .media-grid-breakpoint-container {
    display: flex;
    margin: 0 auto;
    width: 100%;

    @include custom-width(1080px) {
      max-width: none;
    }

    @include custom-width(1320px) {
      max-width: none;
    }

    @include custom-width(1560px) {
      max-width: none;
    }

    @include custom-width(1800px) {
      max-width: none;
    }
  }
}
