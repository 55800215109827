@import "~styles/media-queries";
@import "react-modal";
@import "dark";

.base-modal {
  background: var(--modal-background);
  color: var(--primary-text);
  min-height: 100px;
  width: 80%;
  padding: 30px;
  z-index: 2;

  @include sm {
    width: auto;
  }

  //helpers
  &.v2 {
    padding: 0;
  }
  &.no-background {
    background: none;
  }
  .base-modal-header {
    font-size: 20px;
    font-weight: 500;
  }

  .btn-close {
    position: absolute;
    top: 1px;
    right: 4px;
    padding: 8px;
    color: inherit;

    &:hover {
      color: #777;
    }
  }
}

.base-modal.v2 {
  .btn-close {
    top: 14px;
    right: 10px;
    font-size: 18px;
  }
}

.base-modal.full-screen {
  height: 100vh;
  width: 100vw;

  .btn-close {
    display: none;
  }
}
