#trouble-downloading-header {
  text-align: center;

  .base-modal-header {
    font-weight: normal;
    color: #8a8a8a;
    font-size: 26px;
  }
}

.trouble-downloading-modal {
  .prompts {
    margin: 0 0 10px 0;
  }

  p {
    line-height: 1.6;
    margin: 0;
  }

  .modal-input-line {
    display: flex;
    width: 100%;
    align-items: stretch;
    height: 55px;

    .email-input-form {
      height: 100%;
      width: 100%;
      display: flex;

      .email-input {
        width: 100%;
        font-family: inherit;
        font-size: 1rem;
        font-weight: normal;
        color: #0a0a0a;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      .send-btn {
        cursor: pointer;
        color: white;
        border: none;
        font-size: 16px;
        padding: 0px 15px 0px 15px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;

        &.disabled {
          opacity: .5;
          cursor: not-allowed;
        }
      }
    }
  }
}
