@import "dark";

.contact-tab {
  padding-bottom: 7%;

  .mailto {
    color: #236dfa;
  }

  .presence-indicator {
    border-radius: 50%;
    border: 2px solid var(--app-background-2);
  }

  //Primary Contact Header
  .contact-header {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;

    .avatar-container {
      margin: 0 auto;
      margin-bottom: 5px;
    }
  }

  .contact-header-row {
    font-size: 1em;
    top: 0;

    &.emphasized {
      font-size: 1.3em;
      font-weight: bold;
    }

    &.skeleton {
      height: 15px;
      width: 50%;
      margin: 0 auto;
      margin-top: 10px;

      &.emphasized {
        height: 23px;
      }
    }
  }

  .contact-header-name-container {
    display: flex;
    justify-content: center;

    #star-icon {
      margin-left: 5px;
    }
  }

  .contact-details {
    padding: 15px 15px 0 35px;

    .row {
      display: flex;
      margin: 5px 5px 2px 0;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .label {
      min-width: 85px;
      width: 85px;
      text-transform: uppercase;
      color: #bbbbbb;
      margin-right: 8px;
    }
    .value {
      width: calc(100% - 85px);
      font-size: 1em;
      word-wrap: break-word;
    }
  }

  //Members
  .members-container {
    margin-top: 20px;
    overflow: auto;
    border-top: 2px solid var(--action-tray-separator);
  }

  .members-header {
    margin-top: 20px;
    font-size: 1em;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }

  .member-row {
    border-bottom: 1px solid var(--action-tray-separator);
    height: 80px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .member-presence {
      display: none;
      background-color: inherit;
      margin-left: 10px;
      border-radius: 50%;
      position: relative;
      left: 20px;
      top: -18px;
      height: 14px;
      width: 14px;
      padding: 2px;
      &.online {
        display: flex;
        background-color: #54c771;
      }
      &.away {
        display: flex;
        background-color: #fbbd08;
      }
    }

    .member-details {
      display: flex;
      flex-direction: column;
      margin-left: 15px;

      .member-email {
        font-size: 14px;
        margin-bottom: 3px;

        #email-tooltip {
          font-weight: 600;
        }
      }

      .member-activity {
        font-weight: 500;
        font-size: 12px;
        color: #bbbbbb;
      }
    }
  }

  .member-avatar {
    margin-left: 10px;

    .avatar-container {
      height: 45px;
      width: 45px;
      font-size: 18px;
    }

    .presence-tooltip {
      margin: auto;
      height: 15px;
      line-height: 15px;
    }
  }

  .member-details-container {
    .top-row {
      margin: 10px 15px 20px 15px;
    }

    .btn-back {
      color: #bdbdbd;
      font-size: 14px;
      cursor: pointer;

      span {
        margin-left: 10px;
      }
    }

    .member-details-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .details-avatar {
        margin-bottom: 20px;
      }
      #member-email,
      .last-activity {
        font-size: 14px;
        margin-bottom: 8px;
      }

      .last-activity {
        margin-bottom: 20px;
      }

      .btn-remove-member {
        width: 160px;
        height: 30px;
        font-size: 14px;
        white-space: nowrap;
        text-align: center;
        padding: 0px;
      }

      .member-delete-prompt {
        width: 85%;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 15px;
      }
    }
  }

  //Notification Recipients
  .notification-recipients-container {
    overflow: auto;
  }

  .contact-me-button {
    background-color: var(--action-tray-button-background);
    border-radius: 5px;
    color: white;
    position: absolute;
    right: 10px;
    font-size: 15px;
    height: 25px;
    width: 60px;
    display: flex;
    line-height: 25px;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }

  .btn-delete {
    background-color: #af2026;
    color: white;
    border: 0;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    position: absolute;
    right: 25px;
    display: flex;
    line-height: 25px;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }

  //Tooltips
  #admin-tooltip {
    padding: 10px;
    font-weight: 500;
  }
}
