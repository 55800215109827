.dark .comments-tab-container {
  .comment-action-button {
    background-color: #646464;
    color: var(--dk-primary-text);
  }

  .submit-comment-button {
    background: #444;
  }
}
