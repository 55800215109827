@import "~styles/media-queries";
@import "dark";

.comments-tab-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .comment-input-container {
    background: none;
    height: 120px;
    display: flex;
    align-self: center;
    justify-content: flex-end;
    margin-top: 10px;
    bottom: 0;
    width: 92%;
    flex-shrink: 0;
    padding-bottom: 20px;
    margin-bottom: 7%;

    @include sm {
      padding-bottom: 0px;
    }
  }

  .comment-input {
    position: relative;
    min-height: 95%;
    min-width: 95%;
    height: auto;
    width: auto;
    resize: none;
    font-size: 0.9em;
    border: 1px solid #383838;
  }

  .comment-form-container {
    background-color: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    width: 100%;
    padding: 1px;
    bottom: 10px;
  }

  .typing-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-left: 15px;
  }

  #someone-typing-text {
    margin-left: 5px;
    font-size: 12px;
  }

  // TODO: DELETE AFTER DEPLOY OF MENTIONS
  .non-mentions-comment-form-container {
    position: relative;
    display: flex;
    border: 1px solid #acacac;
    border-radius: 2px;
    width: 100%;
    padding: 1px;
  }

  .comment-form {
    background-color: inherit;
    width: 100%;
    height: 100%;
  }

  .comments-textarea__control,
  .edit-comment-textarea__control {
    background-color: inherit;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 13px;
  }

  .edit-comment-textarea__control {
    margin-top: 0px;
  }

  .comments-textarea__input,
  .edit-comment-textarea__input {
    background-color: #fff;
    border-radius: 3px;
    transition: 0.3s ease-in-out;
    padding: 9px;
    height: fit-content;
    font-size: 10px;
    color: inherit;
    margin-left: 0px !important;
    border: 1px solid #dedede;

    &:focus,
    &:active {
      outline: none;
      border: 1px solid #dedede;
    }
    &::placeholder {
      color: #a4b0be;
      font-size: 13px;
    }
  }
  .comments-textarea {
    background-color: inherit;
  }
  .comments-textarea__suggestions {
    background-color: inherit !important;
  }
  .comments-textarea__suggestions__list,
  .edit-comment-textarea__suggestions__list {
    background-color: inherit;
    border: 1px solid #383838;
    font-size: 14px;
    max-height: 100px;
    overflow: auto;
  }

  .comments-textarea__highlighter,
  .edit-comment-textarea__highlighter {
    padding: 10px;
  }

  .comments-textarea__suggestions__item,
  .edit-comment-textarea__suggestions__item {
    background-color: inherit;
    padding: 5px 15px;
    border-bottom: 1px solid #383838;
  }
  .comments-textarea__suggestions__item--focused,
  .edit-comment-textarea__suggestions__item--focused {
    background-color: rgba(128, 128, 128, 0.15);
  }

  .comments-textarea--multiLine {
    background-color: inherit;
    height: 100%;
  }

  .edit-comment-textarea--multiLine {
    background-color: inherit;
    width: 95%;
  }

  .comment-input__input:focus,
  .comment-input:focus {
    outline: none;
  }

  .mentions__mention {
    background-color: #dedede;
    opacity: 0.7;
  }

  .submit-comment-button:active {
    background-color: #c5c5c5;
  }

  .submit-comment-button {
    display: flex;
    justify-content: center;
    padding: 8px;
    border-radius: 3px;
    border: none;
    position: absolute;
    bottom: 0px;
    right: 3%;
    height: auto;
    background-color: var(--button-background-1);
  }

  // TODO: DELETE AFTER DEPLOY OF MENTIONS
  .non-mentions-submit-comment-button {
    display: flex;
    justify-content: center;
    padding: 8px;
    border-radius: 3px;
    border: none;
    position: absolute;
    bottom: 6%;
    right: 3%;
    height: auto;
  }

  .submit-icon {
    color: #ffffff;
  }

  .comments-container {
    width: 100%;
    margin-top: 10px;
    height: auto;
    overflow: auto;
    flex-grow: 1;
  }

  .comment-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    transition: opacity 1000ms linear;
    animation-duration: 0.5s;
  }

  .comment-left {
    width: 20%;
    text-align: left;
    display: flex;
    justify-content: center;
  }

  .comment-right {
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  .comment-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  .comment-user {
    margin-right: 10px;
    font-weight: 650;
    word-break: break-all;
  }

  .member-avatar {
    .user-avatar {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      line-height: 0px;
      object-fit: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  .comment-body {
    font-size: 0.9em;
    overflow-wrap: break-word;
    max-width: 90%;
  }

  .comment-user,
  .comment-timestamp {
    color: #acacac;
  }

  .comment-timestamp {
    font-style: italic;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .comment-avatar-container {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-comment-button:focus {
    outline: none;
  }

  .comment-footer {
    margin-top: 5px;
  }

  .comment-action-button {
    -webkit-appearance: none;
    background-color: #e5e5e5;
    color: var(--primary-text);
    font-size: 0.7em;
    height: auto;
    padding: none;
    border: none;
    margin-left: none;
    margin-right: 4px;
  }

  .comment-action-button:hover {
    background-color: #c5c5c5;
    color: white;
  }

  .comment-action-button:focus {
    outline: none;
  }

  .comment-action-button:disabled,
  .comment-action-button:disabled:hover {
    background-color: #e5e5e5;
    color: black;
    opacity: 0.6;
  }

  .edit-comment {
    border: 1px solid #acacac;
    border-radius: 3px;
    resize: none;
    font-size: 0.9em;
    width: 90%;
    min-height: 60px;
  }

  .edit-comment:focus {
    outline: none;
  }

  .mentionSuggestionsEntry {
    background-color: black;
  }

  //avatars
  .presence-indicator {
    border-radius: 50%;
    border: 2px solid var(--app-background-2);
  }
}
