.dark {
  .add-media-header button:hover {
    background: rgba($color: #fff, $alpha: 0.2);
  }

  .media-header {
    background: #212121;
    color: var(--white);

    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    ::-ms-placeholder,
    ::placeholder {
      color: grey;
    }
  }
}
