.unauthorized-page {
  text-align: center;

  p.content {
    font-size: 20px;
    text-align: center;
    margin: 50px auto 0 auto;
    max-width: 70vw;
  }

  .success-message {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 650;
  }

  button {
    background: var(--button-background-1);
    color: white;
    margin-top: 20px;

    &:disabled {
      background-color: #dedede;
      cursor: default;
    }
  }
}
