.media-list {
  color: var(--primary-text);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0px;

  .media-list-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px 10px 15px;
    font-size: 14px;
    border-bottom: 1px solid #414141;
    color: var(--primary-text);

    .selection-btn {
      text-decoration: underline;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: var(--primary-text);
    }
  }

  .list-container {
    overflow-y: auto;
    .list {
      .list-item {
        display: flex;
        height: 50px;
        margin-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #414141;
        align-items: center;
        cursor: pointer;

        .checkbox {
          transform: scale(0.9);
        }

        //checkbox styling
        .MuiButtonBase-root {
          color: var(--primary-text);

          &.Mui-checked {
            .MuiIconButton-label {
              color: var(--button-background-1);
              background: white;
              width: 15px;
              height: 15px;
              margin: 5px 5px 5px 4px;
            }
          }
        }

        &.selected {
          background-color: #e5e5e5;
        }

        .media-thumb {
          min-width: 50px;
          height: 70%;

          border-radius: 3px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin-right: 10px;
          background-color: grey;
        }

        .info {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          align-items: center;
          font-size: 14px;

          .type {
            color: var(--primary-text);
          }
        }
      }
    }
  }

}

@import "_dark";
