.dark .tab-content {
  .search-container {

    .search-input-container {

      input {
        background-color: rgb(81, 81, 81);
        color: white;

      }

      .fa {
        color: white;
      }
    }

    .suggestion-container {
      .sub-title {
        color: #464646;
      }

      .suggestion {
        &:hover {
          background: var(--night-rider);
        }
      }
    }
  }
}
