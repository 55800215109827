@import "~styles/media-queries";
@import 'dark';
$break-height: 680px;

.react-tooltip {
  width: 270px;
  font-size: 12px;
  font-weight: normal;
  font-family: HelveticaNowDisplay, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.share-link-builder {

  .content {
    padding: 18px 20px;
  }

  //Buttons
  .btn {
    border: none;
    outline: none;
    height: 45px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: white;
    cursor: pointer;

    &.btn-share {
      width: auto;
      padding: 9px 20px;
    }
  }

  h4,
  .main {
    font-weight: 500;
    padding: 0;
    margin: 0;
  }

  h4.subtitle {
    margin-bottom: 15px;
    font-size: 14px;
  }

  .section {
    font-size: 14px;
  }

  .section.info-container {
    display: flex;
    margin-bottom: 20px;

    .info-column {
      flex-grow: 1;
    }

    .title-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
    }

    .title {
      margin-right: 8px;
    }

    button.tooltip {
      color: var(--modal-accent-text);
      background: none;
      border: none;
      font-size: 15px;
    }

    .tooltip-component {
      bottom: 34px;
    }
  }

  .section.link {
    .title {
      margin-bottom: 8px;
    }

    .input-container {
      @include md {
        display: flex;
      }
    }

    .input-wrap {
      width: 100%;
      flex-grow: 1;
      margin-right: 10px;
      margin-bottom: 5px;

      @include md {
        width: auto;
        margin-bottom: 0px;
      }
    }

    input {
      width: 100%;
      height: 35px;
      border-radius: 4px;
      font-size: 14px;
      padding: 0 10px;
      border: none;
      color: #414141;
      outline: none;
      box-sizing: border-box;
    }
  }

  .fa {
    margin-right: 5px;
  }

  .share-controls {
    border-top: 0.5px solid #414141;
    padding: 18px 20px;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include md {
      justify-content: flex-end;
    }

    button {
      margin-left: 20px;
    }
  }
}
