:root {
  //use this : https://chir.ag/projects/name-that-color/#6195ED
  //or this: https://www.color-name.com/hex/121212

  //colors
  --black: #000000;
  --white: #ffffff;

  //modus
  --dodger-blue: #337aff;
  --primary-blue: #4182ff;
  --primary-red: #a22b2b;
  --secondary-red: #eb6a6a;

  //greys
  --concrete: #f3f3f3;
  --wild-sand: #f4f4f4;
  --gallery: #eeeeee;
  --gallery-2: #ebebeb;
  --mercury: #e5e5e5;
  --silver: #bbbbbb;
  --silver-2: #cccccc;
  --alto: #d8d8d8;
  --dove-gray: #646464;
  --granite-grey: #5f5f5f;
  --matterhorn: #535353;
  --tundora: #464646;
  --mine-shaft: #303030;
  --onyx: #383838;
  --night-rider: #323232;
  --raisin: #212121;
  --cod-gray: #121212;
  --silver: #c1c1c1;

  //light mode
  --app-background: var(--wild-sand);
  --app-background-2: var(--white); //components that exist directly above main body (footer/navbar)
  --primary-text: var(--black);

  --action-tray-button-background: var(--silver);
  --action-tray-separator: var(--alto);

  --button-background-1: var(--dodger-blue);
  --button-hover-background-1: #1a6aff;
  --button-background-2: var(--tundora);

  --dropdown-background: var(--white);
  --dropdown-background-accent: var(--concrete);

  --media-item-background: var(--silver-2);

  --modal-background: var(--white);

  //dark mode
  --dk-app-background: var(--cod-gray);
  --dk-app-background-2: var(--black);
  --dk-primary-text: var(--gallery);

  --dk-action-tray-button-background: var(--onyx);
  --dk-action-tray-separator: var(--onyx);

  --dk-dropdown-background: var(--tundora);
  --dk-dropdown-background-accent: var(--onyx);

  --dk-modal-background: var(--mine-shaft);
  --dk-modal-background-accent: var(--onyx);

  --modal-accent-text: var(--silver);

  --dk-media-item-background: var(--dove-gray);

  .app.dark {
    --app-background-2: var(--black);
  }
}
