@import "~styles/media-queries";

.navbar-actions {
  display: flex;
  align-items: center;
  padding-right: 20px;

  //General
  .btn {
    color: white;
    background-color: var(--button-background-1);
    //height: 34px;
    padding: 4px 15px;
    margin-left: 10px;
    font-weight: 500;

    &:disabled {
      color: #666;
      background: #d9d9d9;
    }

    @include sm {
      padding: 4px 30px;
    }

    //inner icon
    i {
      display: none;
      margin-right: 6px;

      @include sm {
        display: inline;
      }
    }
  }

  //custom buttons
  .btn-edit-content {
    .sm-label {
      @include sm {
        display: none;
      }
    }

    .label {
      display: none;

      @include sm {
        display: inline;
      }
    }
  }

  //buttons
  .btn.alt {
    background-color: var(--button-background-2);
  }

  .settings-icon-btn {
    margin-left: 10px;
    border-radius: 4px;
  }
}
