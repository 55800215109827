@import "styles/media-queries";

#pspdfkit_container {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    @include sm {
        //padding-left: 25px;
    }
}
