//mind order

@font-face {
  font-family: "HelveticaNowDisplay";
  //src: local("HelveticaNowDisplayBold"), url("/assets/fonts/HelveticaNowDisplayBold.woff") format("woff");
  src: local("HelveticaNowDisplayBold"), url("/assets/fonts/HelveticaNowDisplayBold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "HelveticaNowDisplay";
  //src: local("HelveticaNowDisplayMedium"), url("/assets/fonts/HelveticaNowDisplayMedium.woff") format("woff");
  src: local("HelveticaNowDisplayMedium"), url("/assets/fonts/HelveticaNowDisplayMedium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "HelveticaNowDisplay";
  //src: local("HelveticaNowDisplayLight"), url("/assets/fonts/HelveticaNowDisplayRegular.woff") format("woff");
  src: local("HelveticaNowDisplayLight"), url("/assets/fonts/HelveticaNowDisplayRegular.ttf") format("truetype");
}
