@import "dark";

#members_list_modal_base {
  .btn-close {
    position: absolute;
    right: 32px;
    font-size: 21px;
    background: none;
    border: none;
    cursor: pointer;
    display: block;
    top: 20px;
    color: inherit;
  }

  .members-modal-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 18px 35px 18px 20px;
    border-bottom: 1px solid #414141;

    .title-bar {
      display: flex;
      flex-direction: column;
      padding: 0px;

      .title {
        display: flex;

        .members-count {
          margin-left: 10px;
          background-color: grey;
          border-radius: 40px;
          height: 22px;
          width: 45px;
          display: flex;
          justify-content: center;
          color: white;
          font-size: 14px;
        }
      }

      .dsr-name {
        color: grey;
        font-size: 14px;
        font-weight: bold;
        width: 100%;
      }
    }
  }

  .members-list {
    height: calc(100% - 84px);
    overflow: auto;
  }

  .member-item {
    display: flex;
    align-items: center;
    padding: 10px 30px 10px 14px;
    border-bottom: 1px solid #414141;

    .avatar-container {
      height: 55px;
      width: 55px;
      font-size: 16px;
      padding: 3px;

      &.highlighted {
        border: 2px solid #4182ff;
      }
    }

    .info-container {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;
      font-size: 16px;

      .name {
        font-weight: bold;
      }
    }

    .role-container {
      display: flex;
      color: grey;
      padding-top: 2px;

      .admin-star-tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
    }
  }
}
