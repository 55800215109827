.video-thumbnail-generator {
  min-width: 800px;

  //main content container
  .main {
    height: 300px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 100px 1fr;
    gap: 20px;
    align-items: center;
    //margin-top: 10px;

    .video-container {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    video {
      max-width: 80%;
      max-height: 80%;
      border-radius: 6px;
    }

    .photo-button-wrapper {
      justify-self: center;
      border-radius: 50%;
      height: 60px;
      width: 60px;
      border: 4px solid var(--button-background-1);
      padding: 4px;
    }

    button.btn-round {
      box-sizing: border-box;
      background-color: var(--button-background-1);
      border-radius: 50%;
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      color: white;
      font-size: 25px;
      transition: all 100ms ease-in-out;

      &:hover {
        transform: scale(0.95);
        background-color: var(--button-hover-background-1);
      }
    }

    .output-container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      position: relative;

      .fa {
        font-size: 50px;
        color: #777;
      }

      #output_placeholder {
        box-sizing: border-box;
        border-radius: 6px;
        min-height: 80%;
        min-width: 80%;
        background-color: #eee;
        border: 2px #888 dashed;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      canvas {
        position: absolute;
        max-width: 79%;
        max-height: 79%;
      }
    }
  }

  //progress
  .progress-container {
    display: grid;
    grid-template-columns: 80px 1fr 100px 140px 100px 1fr 80px;
    gap: 5px;
    height: 50px;
    background-color: #eeeeee;
    border-radius: 20px;
    margin-top: 25px;

    .text {
      font-weight: normal;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #bbb;
      transition: color 400ms;

      &.active {
        font-weight: 500;
        color: #222;
      }

      &.complete {
        font-weight: 700;
        color: var(--primary-blue);
      }
    }

    .space {
      width: 100%;
      height: 1px;
    }

    .momentum {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .inactive-line {
        width: 60px;
        height: 2px;
        background-color: #bbb;
      }

      //arrows
      //https://codepen.io/susma/pen/PBVLgp
      .arrow {
        box-sizing: border-box;
        padding: 0 10px;
        width: 100%;
        height: 40px;
        position: absolute;

        svg {
          display: none;
          width: 100%;
          height: 100%;

          .draw-arrow {
            stroke-width: 4;
            stroke: var(--primary-blue);
            fill: none;
            stroke-dasharray: 400;
            stroke-dashoffset: 400;
            animation-duration: 500ms;
            animation-fill-mode: forwards;
            animation-name: draw;
            animation-delay: 0;
            stroke-linejoin: round;
            stroke-linecap: round;

            &.tail-1 {
              animation-delay: 100ms;
            }
            &.tail-2 {
              animation-delay: 200ms;
            }
          }

          @keyframes draw {
            to {
              stroke-dashoffset: 0;
            }
          }
        }
      }

      &.active {
        font-weight: 500;
        color: #222;
      }

      &.complete {
        .arrow svg {
          display: block;
        }
        .inactive-line {
          width: 0;
          transition: all 500ms;
        }
      }
    }
  }

  //Actions container
  .actions {
    margin-top: 20px;
    display: flex;

    .space {
      flex-grow: 1;
    }

    .btn.cancel {
      background-color: var(--button-background-2);
      color: white;
    }

    .btn.done {
      min-width: 100px;
      background-color: var(--button-background-1);
      color: white;

      &:disabled {
        background-color: #aaa;
        color: #ddd;
        cursor: no-drop;
      }
    }
  }

  //overlay
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #cccccc, $alpha: 0.4);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }
}
