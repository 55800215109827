$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1450px
);

@mixin xs {
  @media (min-width: #{map-get($breakpoints, xs)}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{map-get($breakpoints, sm)}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{map-get($breakpoints, md)}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{map-get($breakpoints, lg)}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{map-get($breakpoints, xl)}) {
    @content;
  }
}

@mixin custom-width($width) {
  @media (min-width: #{$width}) {
    @content;
  }
}

@mixin custom-height($height) {
  @media (min-height: #{$height}) {
    @content;
  }
}

@mixin custom-height($size) {
  @media (min-height: #{$size}) {
    @content;
  }
}
