.dropdown-container {
  position: relative;

  .filter-dropdown-container {
    position: absolute;
    border-radius: 4px;
    right: 0px;
    border: 1px solid #666;
    color: var(--primary-text);
    background-color: var(--dropdown-background);
    width: fit-content;
  }
}

@import 'dark';
