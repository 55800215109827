.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .submit-button {
    max-width: 50%;
    min-width: 30%;
    margin-left: auto;
    margin-right: auto;
    background-color: #4182ff;
    color: white;
    font-weight: 500;
    border: none;
  }

  .submit-button:focus {
    outline: none;
  }

  .prompt {
    margin-bottom: 30px;
    font-weight: 500;
    text-align: center;
  }

  .new-user-input {
    margin-bottom: 20px;
    min-height: 30px;
    background: none;
    color: inherit;
  }

  .error {
    min-height: 30px;
    margin-bottom: 20px;
  }

  .info-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    margin-top: 15px;

    #info-circle {
      margin-left: 5px;
    }
  }

  .company-logo.center {

    .logos-container {
      justify-content: center;
    }

    img {
      object-position: center;
    }
  }
}
