input {
  //height: 34px;
  border: 1px solid #cacaca;
  border-radius: 2px;
  outline: none;
  padding: 1px 0px 1px 12px;

  &.error {
    border: 1px solid red;
  }
}
