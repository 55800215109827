.tab-content {
  .search-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .search-input-container {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0px 10px 10px 15px;
      flex-grow: 0;

      input {
        width: 70%;
        color: var(--primary-text);
        border: 1px solid #464646;
        border-radius: 4px;
        caret-color: var(--primary-blue);
        outline: none;
        font-size: 14px;
        padding: 6px 34px;

        margin-right: 10px;

        &::placeholder {
          color: white;
        }

        &:focus {
          background: none;
          border: 1px solid var(--primary-blue);
        }
      }

      .fa {
        font-size: 14px;
        color: var(--primary-text);
      }

      .fa-arrow-right {
        color: white;
      }



      .fa-search {
        position: absolute;
        left: 9px;

        &::placeholder {
          color: white;
        }
      }

      .submit-button {
        cursor: pointer;
      }

      .clear-search-button {
        position: absolute;
        right: 40px;
        border: none;
        background: none;
        font-size: 16px;
        cursor: pointer;
      }

      .search-button {
        border-radius: 50%;
        background: var(--primary-blue);
        border: none;
        color: #fff;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: .6;
        cursor: not-allowed;

        &.active {
          opacity: 1;
          cursor: pointer;
        }
      }
    }

    .suggestion-container {
      margin: 0 15px 0 15px;
      border-radius: 3px;

      .sub-title {
        font-weight: bold;
        font-size: 13px;
        color: #464646;
        margin-left: 5px;
      }

      .suggestion {
        cursor: pointer;
        margin: 5px 0 5px 0;
        padding: 0px 5px 0px 5px;
        border-radius: 4px;
        text-overflow: ellipsis;

        .suggestion-icon {
          color: #acacac;
          margin-right: 10px;
        }

        .suggestion-text {
          display: inline-block;

          em {
            color: var(--primary-blue);
          }
        }

        &:hover {
          background: #acacac;;

          .fa-tag {
            color: var(--primary-blue);
          }
        }
      }
    }
  }
}


@import "_dark";
