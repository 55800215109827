@import "~styles/media-queries";

.legacy-info-bar {
  height: auto;
  padding: 5px 0px 10px 15px;
  background-color: #f2f2f2;
  box-shadow: #7c7c7c 1px -2px 10px;
  display: grid;
  grid-template-columns: 1fr 0 auto;
  grid-template-rows: 55px 30px;

  @include sm {
    grid-template-columns: max-content 1fr auto;
    grid-template-rows: 55px;
  }

  div {
    box-sizing: border-box;
  }

  .one {
    //background: red;
    display: flex;
    align-items: center;
    padding-right: 10px;
  }

  .two {
    //background: blue;
    display: flex;
    align-items: center;
    grid-column: 1 / span 3;
    grid-row: 2;
    font-size: 1.1em;
    color: #7c7c7c;
    padding: 0px 5px 0 30px;

    @include sm {
      grid-column: 2;
      grid-row: 1;
      margin: 0;
      padding: 0px 0px;
    }
  }

  .three {
    //background: yellow;
    display: flex;
    align-items: center;
    grid-column: 3;
    grid-row: 1;
  }

  //Logo
  .logos-container {
    height: 100%;
  }

  .logos-container .company-logo {
    max-height: 100%;
    max-width: 150px;
    height: auto;
  }

  //Size and Download
  .size-container {
    font-size: 1.2em;
    color: #b2b2b2;
    font-weight: bold;
    margin-right: 15px;
    flex-grow: 0;

    @include md {
      font-size: 1.7rem;
    }
  }

  .download-button {
    background: none;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 150%;
    padding: 0 15px;
    border: none;
    border-radius: 3px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    span {
      display: none;
    }

    @include md {
      color: #fff;
      background: var(--button-background-1);
      width: 170px;

      svg {
        color: white;
        margin-right: 10px;
      }

      span {
        display: inline;
      }
    }
  }

  .question-btn {
    align-items: center;
    padding: 0px 10px 0px 10px;
    color: #222;
    cursor: pointer;
    font-size: 20px;
  }
}
