@import "~styles/media-queries";
@import 'dark';

//Modal Component
.share-modal-header {
  background-color: var(--modal-background);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.5px solid #414141;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.title-bar {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 18px 35px 18px 20px;

  .title {
    font-size: 18px;
    font-weight: bold;
  }
}

.share-modal {
  --share-modal-border-color: #666;
  background-color: var(--modal-background);
  border-radius: 0 0 6px 6px;
  overflow: hidden;
  max-width: 670px;
  
  @include sm {
    width: 730px;
  }

  .default-view {
    margin: 0 auto;
    position: relative;
    padding: 10px 20px;
    background-color: var(--modal-background);

    .message {
      text-align: center;
    }

    ul {
      margin: 16px 0 0 0;
      padding: 0;
    }

    li {
      list-style: none;
      display: flex;
      align-items: center;
      border: .5px solid #414141;
      border-radius: 4px;
      padding: 15px 15px;
      margin: 8px 0;
      cursor: pointer;

      &:hover {
        background-color: rgba($color: #666666, $alpha: 0.2);
      }

      &.disabled {
        color: #777;
        background: #444;
        opacity: 0.4;
      }
    }

    .icon {
      background-color: white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.5px solid #414141;
      margin-right: 14px;
      flex-shrink: 0;
    }

    .title {
      font-weight: 700;
    }

    .details {
      font-size: 13px;
    }
  }
}
