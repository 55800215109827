@import "styles/media-queries";

.request-access-gate {
  width: 550px;

  .back-btn {
    background-color: transparent;
    display: flex;
    align-items: center;
    align-content: center;
    padding: 0px;
    color: white;

    .arrow-icon {
      font-size: 25px;
      padding-right: 15px;
    }
  }

  .information {
    margin-top: 40px;

    .lock-icon {
      font-size: 23px;
    }

    .request-access-label {
      font-size: 25px;
      padding-left: 16px;
    }

    .message {
      margin-top: 5px;
    }
  }

  //Form Elements
  form {
    width: 400px;
  }

  input {
    width: 100%;
    margin-top: 15px;
  }

  textarea {
    margin-top: 15px;
    width: 100%;
    box-sizing: border-box;
    height: 100px;
    font-size: 16px;
    padding: 16px 16px 16px 12px;
    background: transparent;
    border: 1px solid #666;
    resize: none;
    font-family: inherit;
    color: white;
    border-radius: 4px;

    &::placeholder {
      color: white;
    }

    &:focus {
      background: none;
      border: 1px solid var(--primary-blue);
    }
  }

  .user-access-input {
    background: rgba(220, 220, 220, 0.18);
    border: none;
    padding-right: 0px;
    max-width: 375px;
    padding-right: 0px;
    padding: 2px 0px 2px 12px;

    &:focus {
      background: none;
      border: 1px solid var(--primary-blue);
      padding: 1px 0px 1px 12px;
    }
  }

  .actions {
    margin-top: 15px;

    .btn-send {
      height: 40px;
    }
  }
}
