.btn {
  display: inline-block;
  text-align: center;
  font-size: 16px;
  padding: 6px 20px;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  &:disabled {
    background: #999;
    color: #666;

    &:hover {
      background: #999;
    }
  }
}

//TODO: update this to use theme colors
.btn-primary {
  background: #4182ff;
  color: white;

  &:hover {
    background: #337aff;
  }
}

.btn-no-style {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 1px;
  margin: 0;
}
