@import "~styles/media-queries";
@import "dark";

.media-grid-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  &.fade-enter-active {
    overflow: hidden;
  }
}

.media-grid {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;

  @include lg {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.media-items-container {
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;

  @include custom-width(850px) {
    justify-content: flex-start;
    max-width: 450px;
  }
}

.sortable-media-container {
  width: 100%;
}

.add-media-tile {
  .add-media-button {
    cursor: pointer;

    svg {
      background: none;
      border: none;
      width: 24px;
      height: 24px;
      color: #787878;
    }
  }

  button {
    background-color: var(--media-item-background);
    margin: 0px;
    height: 135px;
    width: 100%;
    border: none;
    border-radius: 0px;
    border-radius: 4px;
    position: relative;

    &:focus {
      outline: none;
    }
  }

  h3 {
    margin: 8px 0px 0px 0px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
  }

  p {
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--dove-gray);
  }
}

@import "~styles/media-grid-breakpoints";
