.dark .add-media-tray-container {
  .add-media-tray {
    .MuiTabs-flexContainer {
      color: white;
    }
  }

  .tab-container {

    .button-container {
      background-color: var(--button-background-2);

      .cancel-btn {
        color: white;
      }
    }
  }
}
