@import "styles/media-queries";

.centered-gate-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .modus-logo {
    height: 35px;
    margin-bottom: 30px;
  }

  .gate-content {
    @include md {
      width: 100%;
      padding-left: 125px;
    }
  }
}
