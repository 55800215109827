.dark {
  .share-settings-modal-header {
    border-bottom: 1px solid #404040;
  }

  .share-settings-modal {
    .default-settings-container {
      background-color: #404040;
      color: white;
    }

    .settings-container {
      border-bottom: 1px solid #404040;
    }

    .privacy-setting-description-container {
      border-left: 1px solid #404040;
    }

    .modal-action-btns .btn-cancel {
      background: #515151;
      color: white;
    }

    input {
      background-color: #404040;
      border-color: #404040;
      color: white;
    }

    .advance-settings-container .reveal-icon {
      color: white;
    }
  }
}
