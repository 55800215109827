@import 'dark';
@import 'src/styles/media-queries';

.navbar-title {
  font-size: 14px;

  .title-container {
    display: flex;
    align-items: center;
  }

  .title {
    padding: 2px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;

    @include sm {
      max-width: 650px;
    }
  }

  .title-icon {
    padding: 0;
    margin: 0 0 0 15px;
    cursor: pointer;
  }

  .btn-icon {
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    height: 22px;
    margin-left: 15px;

    .title-icon {
      margin: 0;
    }
  }

  //Editable
  .title-container.editable {
    cursor: pointer;

    &:hover {
      //maybe change font color
      transform: scale(1.1);
      transition: all 0.1s;
    }
  }

  //Editor
  .edit-title-container {
    display: flex;
    align-items: center;
  }

  input {
    background: none;
    border: none;
    color: inherit;
    border-bottom: 1px solid #cdcdcd;
    width: 400px;
    padding: 2px 0px;
    font-size: 14px;
  }

  //spinner
  .spinner-container {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.navbar-title.top-title {
  display: none;

  @include custom-width(768px) {
    display: flex;
  }
}

.navbar-title.mobile-title {
  display: flex;
  background-color: var(--app-background-2);
  width: 100%;
  height: 30px;
  padding: 0 25px;

  @include custom-width(768px) {
    display: none;
  }
}
