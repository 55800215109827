@import "~styles/media-queries";

.dealer-container {
  display: flex;
  align-items: center;
}

#dealer-logo {
  display: block;
  max-height: 25px;
  padding-top: 3px;
  animation: fadeInDown 450ms;
  @include sm {
    padding-top: 0px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    animation: fadeInLeft 450ms;
    max-height: 35px;
    margin-right: auto;
    padding-left: 10px;
  }

  img {
    width: 100%;
    max-height: 100%;
    max-width: 95px;

    @include sm {
      width: 100%;
      max-height: 50px;
      max-width: 180px;
    }
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-delay: 350ms;
  animation-duration: 500ms;
}

.drawDown {
  margin-left: 20px;
  display: inline-block;
  width: 1px;
  animation: grow 300ms 100ms forwards;
  background-color: #d8d8d8;
  display: none;
  @include custom-width(375px) {
    margin-left: 5px;
  }

  @include sm {
    display: inherit;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes grow {
  0% {
    height: 0px;
  }
  100% {
    height: 40px;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}
