.my-stuff {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  color: inherit;
  position: relative;

  .content {
    padding: 0 10px;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
  }

  .buttons {
    display: flex;
    flex-direction: column;

    .media-button {
      background-color: #e5e5e5;
      color: inherit;
      margin: 5px 0 5px 0;
      display: flex;
      padding: 15px;
      align-items: center;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      #external-media-input {
        display: none;
      }

      &:hover {
        background-color: #c5c5c5;
      }

      .icon {
        margin-right: 15px;
      }

      .label {}
    }
  }

  .bread-crumb {
    margin-left: -10px;
    width: 100%;
    font-size: 14px;
    padding: 10px 15px;
    border-bottom: 1px solid #acacac;

    button {
      color: var(--primary-blue);
      border: none;
      background-color: transparent;
      cursor: pointer;
      margin-right: 10px;
      padding: 0px;
    }

    i {
      margin-right: 10px;
    }

    span {
      font-size: 14px;
    }
  }

  .add-link-view {
    color: var(--primary-text);

    form {
      padding: 0 5px;
    }

    .form-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 14px;
      margin-top: 15px;

      label {
        margin: 5px 0 5px 0;
      }

      input {
        color: var(--primary-text);
        border: 1px solid #acacac;
        padding: 10px 15px;
        background: transparent;
        border-radius: 4px;
        font-size: 16px;
      }
    }

    .preview-label {
      margin: 15px 5px;
    }

    .image-preview {
      margin: 15px 5px;
      height: 25%;
      min-height: 200px;

      border-radius: 3px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 10px;
      background-color: grey;
    }

    .image-preview-placeholder {
      margin: 15px 5px;
      background-color: grey;
      display: flex;
      justify-content: center;
      background-color: #acacac;
      align-items: center;

      border-radius: 4px;
      height: 25%;
      min-height: 200px;
      padding: 5px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    height: 50px;
    background-color: #e5e5e5;
    box-sizing: border-box;

    .cancel {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      color: var(--primary-text);
      cursor: pointer;
    }

    .add {
      background-color: var(--primary-blue);
      color: white;
      border: none;
      border-radius: 4px;
      width: 110px;
      cursor: pointer;
      margin-right: 15px;

      &:disabled {
        background: #999999;
      }
    }

    &.active {
      padding: 10px 15px;

      .cancel {
        border: 1px solid #acacac;
        width: 110px;
        border-radius: 4px;
      }
    }
  }
}

;

@import "dark";