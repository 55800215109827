.press-hold-button {
  --color: #ffffff;
  --background: #af2026;
  --icon: var(--color);
  --progress-active: #2b3044;
  --progress-activ2e: #f6f8ff;
  --progress-success: #16bf78;
  --duration: 1600ms;

  --width: 50px;
  --height: 50px;

  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: var(--width);
  height: var(--height);

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    border: 0;
    border-radius: 100%;
    box-sizing: border-box;
    overflow: hidden;

    width: calc(var(--width) - 10px);
    height: calc(var(--height) - 10px);
    outline: none;
    user-select: none;
    cursor: pointer;
    backface-visibility: hidden;
    color: var(--color);
    background: var(--background);

    svg {
      position: absolute;
    }

    .check {
      visibility: collapse;
    }

    &.success {
      svg {
        animation-name: fadeOutDown;
        animation-duration: 0.4s;
        animation-fill-mode: forwards;
      }

      .check {
        visibility: visible;
        animation-name: fadeInCheck;
      }

      .times {
        animation-name: fadeOutDown;
      }
    }
  }

  .circle__box {
    width: var(--width);
    height: var(--height);
    position: absolute;
    top: 0;
    left: 0;
    .circle__wrapper {
      width: calc(var(--width) / 2);
      height: var(--height);
      position: absolute;
      top: 0;
      overflow: hidden;
    }
    .circle__wrapper--right {
      right: 0;
    }

    .circle__wrapper--left {
      left: 0;
    }

    .circle__whole {
      width: calc(var(--width) * 0.8);
      height: calc(var(--height) * 0.8);
      border: 5px solid transparent;
      border-radius: 50%;
      position: absolute;
      top: 0;
      transform: rotate(-135deg);
    }

    .circle__right {
      border-top: 5px solid var(--progress-active);
      border-right: 5px solid var(--progress-active);
      right: 1px;
      //animation: circleRight var(--duration) linear forwards;
    }

    .circle__left {
      border-bottom: 5px solid var(--progress-active);
      border-left: 5px solid var(--progress-active);
      left: 1px;
    }

    &.process {
      .circle__left {
        animation: circleLeft var(--duration) linear forwards;
      }
      .circle__right {
        animation: circleRight var(--duration) linear forwards;
      }
    }
  }

  @keyframes circleRight {
    0% {
      transform: rotate(-135deg);
    }
    50%,
    100% {
      transform: rotate(45deg);
    }
  }

  @keyframes circleLeft {
    0%,
    50% {
      transform: rotate(-135deg);
    }
    100% {
      -webkit-transform: rotate(45deg);
    }
  }

  @keyframes fadeInCheck {
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      color: var(--progress-success);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeOutDown {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
      visibility: collapse;
    }
  }
}

.dark .press-hold-button {
  --dk-progress-active: #ffffff;

  .circle__right {
    border-top: 5px solid var(--dk-progress-active);
    border-right: 5px solid var(--dk-progress-active);
  }

  .circle__left {
    border-bottom: 5px solid var(--dk-progress-active);
    border-left: 5px solid var(--dk-progress-active);
  }
}
