@import '~styles/media-queries';
/*
  Player Skin Designer for Video.js
  http://videojs.com

  To customize the player skin edit
  the CSS below. Click "details"
  below to add comments or questions.
  This file uses some SCSS. Learn more
  at http://sass-lang.com/guide)

  This designer can be linked to at:
  https://codepen.io/heff/pen/EarCt/left/?editors=010
*/

// The following are SCSS variables to automate some of the values.
// But don't feel limited by them. Change/replace whatever you want.
// The color of icons, text, and the big play button border.
// Try changing to #0f0
$primary-foreground-color: #cacaca; // #fff default
$secondary-foreground-color: #b7090b;
// The default color of control backgrounds is mostly black but with a little
// bit of blue so it can still be seen on all-black video frames, which are common.
// Try changing to #900
$primary-background-color: #262626; // #2B333F default
// Try changing to true
$big-play-button: false; // hide big play button
$controls-margin: 2em;
// Make a slightly lighter version of the main background
// for the slider background.
$slider-bg-color: lighten($primary-background-color, 3%);
$progress-position: -2.5em;
@font-face {
  font-family: nf-icon;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/nf-icon-v1-86.eot);
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/nf-icon-v1-86.eot?#iefix)
      format('embedded-opentype'),
    url(https://assets.nflxext.com/ffe/siteui/fonts/nf-icon-v1-86.woff) format('woff'),
    url(https://assets.nflxext.com/ffe/siteui/fonts/nf-icon-v1-86.ttf) format('truetype'),
    url(https://assets.nflxext.com/ffe/siteui/fonts/nf-icon-v1-86.svg#nf-icon-v1-86) format('svg');
  font-weight: 400;
  font-style: normal;
}

.video-spinner {
  position: absolute;
  height: 101%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: #212121;

  & > .spinner-wrapper {
    margin: 0 !important;
  }
}

.video-js {
  /* The base font size controls the size of everything, not just text.
     All dimensions use em-based sizes so that the scale along with the font size.
     Try increasing it to 15px and see what happens. */
  font-size: 16px;
  /* The main font color changes the ICON COLORS as well as the text */
  color: $primary-foreground-color;
  height: 100%;
}

/* The "Big Play Button" is the play button that shows before the video plays.
   To center it set the align values to center and middle. The typical location
   of the button is the center, but there is trend towards moving it to a corner
   where it gets out of the way of valuable content in the poster image.*/

.video-js .vjs-play-control,
.video-js .vjs-remaining-time,
.video-js .vjs-volume-menu-button {
  border-right: 1px solid #323232;
}

.video-js .vjs-volume-menu-button {
  .vjs-menu-content {
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
    .vjs-volume-bar {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

/* The default color of control backgrounds is mostly black but with a little
   bit of blue so it can still be seen on all-black video frames, which are common. */

.video-js .vjs-control {
  &:before {
    font-family: nf-icon;
  }
  &.vjs-play-control {
    &:before {
      content: '\e646';
    }
    &.vjs-playing {
      &:before {
        content: '\e645';
      }
    }
  }
  &.vjs-fullscreen-control {
    &:before {
      content: '\e642';
    }
  }
  &.vjs-volume-menu-button {
    &:before {
      content: '\e630';
    }
  }
  &.vjs-captions-button {
    &:before {
      content: '\e650';
    }
  }

  align-items: center; //move the volume control slider to the center of its containing div
}

.video-js .vjs-volume-tooltip {
  top: -2em;
}

.video-js .vjs-control-bar,
.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
  /* IE8 - has no alpha support */
  background-color: $primary-background-color;
  /* Opacity: 1.0 = 100%, 0.0 = 0% */
  background-color: rgba($primary-background-color, 0.9);
}

.video-js .vjs-control-bar {
  background-color: rgba($primary-background-color, 0.9);
  width: auto;
  bottom: $controls-margin;
  border-radius: 0.5em;
  &:hover {
    .vjs-progress-control {
      opacity: 1;
      top: $progress-position;
    }
  }
  margin: 0 auto;
  @include md {
    max-width: 80%;
  }

  .vjs-menu {
    z-index: 2;
    height: 100%;
  }
}

.video-js.vjs-fullscreen {
  .vjs-control-bar {
    bottom: $controls-margin * 2;
  }
}

.video-js .vjs-current-time {
  display: block;
  position: absolute;
  right: 0;
  top: $progress-position;
}

/* Slider - used for Volume bar and Progress bar */

.video-js .vjs-slider {
  background-color: $slider-bg-color;
  background-color: rgba($slider-bg-color, 0.8);
  border-radius: 1em;
  margin: 0;
}

.video-js .vjs-remaining-time {
  flex: 1;
  text-align: left;
}

/* The slider bar color is used for the progress bar and the volume bar
   (the first two can be removed after a fix that's coming) */

.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.video-js .vjs-slider-bar {
  background: $primary-foreground-color;
  border-radius: 1em;
}

.video-js .vjs-play-progress {
  color: $secondary-foreground-color;
  background: $secondary-foreground-color;
  font-size: 1.3em;
  &:before {
    transition: width 0.1s ease-out, height 0.1s ease-out;
    content: '';
    top: -0.2em;
    border: 0;
    background: radial-gradient(#b7090b 33%, #830607);
    width: 1em;
    height: 1em;
    border-radius: 50%;
    box-shadow: #000 0 0 2px;
  }
  &:hover {
    &:before {
      width: 1.1em;
      height: 1.1em;
      border: 2px solid transparent;
    }
  }
}

.video-js .vjs-progress-control {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 4em 0 0.4em;

  top: $progress-position + 0.2em;
  border-radius: 1em;
  transition: top 150ms linear, opacity 150ms linear, transform 150ms linear,
    -webkit-transform 150ms linear, -moz-transform 150ms linear, -o-transform 150ms linear;
  z-index: 1;
  opacity: 0;
  &:hover {
    .vjs-progress-holder {
      font-size: inherit;
    }
  }
  .vjs-mouse-display {
    &:before {
      bottom: 2px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      border-top-color: #262626;
      border-width: 0.8em;
      left: 2px;
      margin-left: -0.8em;
    }
  }
}

.video-js .vjs-time-tooltip {
  background: $primary-foreground-color !important;
  color: $secondary-foreground-color;
  &:before {
    top: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    border-top-color: #262626;
    border-width: 0.8em;
    right: 25%;
    margin-left: -0.8em;
  }
}

.video-js .vjs-play-progress,
.video-js .vjs-load-progress {
  height: 0.7em !important;
}

.video-js .vjs-progress-holder {
  height: 0.9em;
}

/* The main progress bar also has a bar that shows how much has been loaded. */

.video-js .vjs-load-progress {
  /* For IE8 we'll lighten the color */
  background: lighten($slider-bg-color, 5%);
  /* Otherwise we'll rely on stacked opacities */
  background: rgba($slider-bg-color, 0.5);
  //box-shadow: 0 0.2em 0.1em 0 #000;
  border-radius: 1em;
  height: 0.9em !important;
}

/* The load progress bar also has internal divs that represent
   smaller disconnected loaded time ranges */

.video-js .vjs-load-progress div {
  /* For IE8 we'll lighten the color */
  background: lighten($slider-bg-color, 5%);
  /* Otherwise we'll rely on stacked opacities */
  background: rgba($slider-bg-color, 0.75);
  border-radius: 1em;
  height: 0.9em !important;
}

.vjs-loading-spinner {
  border: none;
  opacity: 0;
  visibility: hidden;
  animation: vjs-spinner-fade-out 2s linear 1;
  animation-delay: 2s;
  &:before,
  &:after {
    border: none;
  }
  &:after {
    background-image: url(https://assets.nflxext.com/en_us/pages/wiplayer/site-spinner.png);
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
  }
}

.video-js.vjs-fluid:not(.vjs-audio-only-mode),
.video-js.vjs-16-9:not(.vjs-audio-only-mode),
.video-js.vjs-4-3:not(.vjs-audio-only-mode),
.video-js.vjs-9-16:not(.vjs-audio-only-mode),
.video-js.vjs-1-1:not(.vjs-audio-only-mode) {
  height: 100%;
  width: 100%;
  padding: 0px;
}

.vjs-seeking .vjs-loading-spinner:after,
.vjs-waiting .vjs-loading-spinner:after {
  animation: vjs-spinner-spin 1.1s linear infinite, vjs-spinner-fade 1.1s linear 1 !important;
  animation-delay: 2s;
}

.vjs-seeking .vjs-loading-spinner,
.vjs-waiting .vjs-loading-spinner {
  opacity: 1;
  visibility: visible;
  animation: vjs-spinner-fade-in 2s linear 1;
  animation-delay: 2s;
}

.video-js.vjs-fluid:not(.vjs-audio-only-mode),
.video-js.vjs-16-9:not(.vjs-audio-only-mode),
.video-js.vjs-4-3:not(.vjs-audio-only-mode),
.video-js.vjs-9-16:not(.vjs-audio-only-mode),
.video-js.vjs-1-1:not(.vjs-audio-only-mode) {
  padding-top: 0px;
  height: 100%;
  background-color: transparent;
}

.video-js .vjs-tech {
  position: relative;
}

.video-js .vjs-control.vjs-play-control:before {
  content: '';
}

.video-js .vjs-control.vjs-fullscreen-control:before {
  content: '';
}

.vjs-big-play-centered .vjs-big-play-button {
  top: calc(50% - 0.75em);
  left: calc(50% - 0.75em);
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  background-color: transparent;
  margin: 0;

  .vjs-icon-placeholder:before {
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.vjs-error .vjs-error-display:before {
  display: none;
}

.vjs-error-display.vjs-modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.videojs .vjs-modal-dialog .vjs-modal-dialog-content {
  height: auto;
  position: relative;
}

@keyframes vjs-spinner-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes vjs-spinner-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}
