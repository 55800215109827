.privacy-gate {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;

  &.is-loading {
    justify-content: center;
    padding-left: 0%;

    .body {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 100vh;
    }

    .modus-logo {
      //center the modus logo
      position: absolute;
      top: 10%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }

    .welcome-message {
      margin-top: 30px;
      font-size: 30px;
      font-weight: bold;
    }

    .message {
      margin-top: 10px;
    }
  }

  .privacy-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    background-color: black;
    top: 0;
    right: 0;
  }

  .blur-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    backdrop-filter: blur(5px);
  }

  .body {
    position: relative;
    display: block;
    color: white;
    height: 100%;
    width: 100%;

    //gate generics across the gates
    .standard-size {
      height: 50px;
      width: 80%;
      max-width: 400px;
      font-size: 16px;
    }

    .modus-logo {
      height: 35px;
    }

    .information {
      max-width: 550px;
      margin-top: 45px;

      .title {
        display: flex;
        font-size: 28px;
        font-weight: 500;
        align-items: center;

        .share-icon {
          font-size: 30px;
          padding-right: 10px;
        }
      }

      .message {
        margin-top: 10px;
        line-height: 1.8;
      }
    }

    .email-input {
      box-sizing: border-box;
      background: transparent;
      border: 1px solid #666;
      border-radius: 4px;
      max-width: 400px;
      width: 100%;
      height: 46px;
      color: white;
      caret-color: var(--primary-blue);
      position: relative;
      padding-right: 40px;
      font-size: 16px;

      &:focus {
        background: none;
        border: 1px solid var(--primary-blue);
      }

      &::placeholder {
        color: white;
      }

      &::-ms-input-placeholder {
        color: white;
      }

      &::-ms-input-placeholder {
        color: white;
      }
    }

    .input-container {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .input-arrow {
        font-size: 26px;
        margin-left: -35px;
        cursor: pointer;
        z-index: 5;
      }
    }

    .seperator {
      margin-top: 10px;
      display: flex;
      position: relative;
      align-items: center;

      .line-div {
        max-width: 185px;
        width: calc(50% - 25px);
        height: 100%;
        position: relative;
        overflow: hidden;

        &:before {
          content: "";
          position: absolute;
          border-bottom: 1px solid #666;
          height: 100%;
          width: 100%;
          transform: translateY(-50%);
        }

        &.full-width-height {
          width: 100%;
          max-width: 100%;
        }
      }

      .text {
        width: 50px;
        text-align: center;
      }
    }

    //Buttons
    .action-btn {
      display: flex;
      margin-top: 10px;
      position: relative;
      align-items: center;
    }

    .linkedIn-icon {
      position: absolute;
      left: 10px;
      font-size: 35px;
    }
  }
}
